import { findByAltText } from "@testing-library/react";
import { toInteger } from "lodash";
import MyComponent from "../../../base_components/my_component";
import MacroArgumentDB from "../../database/education/learn/macro_argument";
import SubjectController from "./subject_controller";
import TopicsListController from "./topics_list_controller";

class MacroTopicController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.name = ""
    this.position = 0
    this.topics = new TopicsListController()
    this.subject = new SubjectController()
    this.subject.setOverrideState((() => this.updateInfo()).bind(this))
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    if(auto_update) this.updateInfo()
  }

  setPosition(value, auto_update = true) {
    value = toInteger(value)
    // type check
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    this.position = value
    if(auto_update) this.updateInfo()
  }

  setTopics(value, auto_update = true) {
    // type check
    if(!(value instanceof TopicsListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "topics is not a TopicsListController instance. Aborted")
      return
    }

    this.topics = value
    if(auto_update) this.updateInfo()
  }

  setSubject(value, auto_update = true) {
    // type check
    if(!(value instanceof SubjectController)) {
      console.warn(`[ ${this.constructor.name} ]`, "subject is not a SubjectController instance. Aborted")
      return
    }

    this.subject = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getName() { return this.name }
  getPosition() { return this.position }
  getTopics() { return this.topics }
  getSubject() { return this.subject }

  // API
  async create(subjectSlug) {
    if(typeof subjectSlug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "subjectSlug is not a string. Aborted")
      return false
    }
    let db = new MacroArgumentDB()
    let result = await db.createMacroArgument({
      name: this.getName(),
      position: this.getPosition()
    }, subjectSlug)
    return result
  }

  async update() {
    let db = new MacroArgumentDB()
    let result = await db.updateMacroArgument({
      id: this.getId(),
      name: this.getName(),
      position: this.getPosition()
    })
    if(result.error) result.message = "Qualcosa è andato storto"
    return result
  }

  async delete() {
    let db = new MacroArgumentDB()
    let result = await db.deleteMacroArgument(this.getId())
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish(subjectSlug) {
    if(this.getId() == "") return await this.create(subjectSlug)
    else return await this.update()
  }

  load() {}

  loadTopics() {}

  loadSubject() {}

  // management
  buildFromExport(data) {
    data = this.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data["id"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    } 
    if(keys.includes("name") && typeof data["name"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    } 
    if(keys.includes("position") && typeof data["position"] != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    } 

    // load data
    if(keys.includes('subject')) {
      this.subject.buildFromExport(data['subject'])
      delete data['subject']
    }
    if(keys.includes('topics')) {
      this.topics.buildFromExport(data['topics'])
      delete data['topics']
    }

    Object.assign(this, data)
    this.updateInfo()
  }
}

export default MacroTopicController