import MyComponent from "../../../base_components/my_component";
import PagesDB from "../../database/education/learn/pages";
import LessonController from "./lesson_controller";

class PageController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.image = ""
    this.content = ""
    this.position = 0
    this.lesson = new LessonController()
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setImage(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }

    this.image = value
    if(auto_update) this.updateInfo()   
  }

  setContent(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "content is not a string. Aborted")
      return
    }

    this.content = value
    if(auto_update) this.updateInfo()   
  }

  setPosition(value, auto_update = true) {
    // type check
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    this.position = value
    if(auto_update) this.updateInfo()   
  }

  setLesson(value, auto_update = true) {
    // type check
    if(!(value instanceof LessonController)) {
      console.warn(`[ ${this.constructor.name} ]`, "lesson is not a LessonController instance. Aborted")
      return
    }

    this.lesson = value
    if(auto_update) this.updateInfo()   
  }

  // getter
  getId() { return this.id }
  getImage() { return this.image }
  getContent() { return this.content }
  getPosition() { return this.position}
  getLesson() { return this.lesson}

  // API
  async create(lesson_slug) {
    let db = new PagesDB()
    let result = await db.createPage({
      image: this.getImage(),
      content: this.getContent(),
      position: this.getPosition()
    }, lesson_slug)

    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async update() {
    let db = new PagesDB()
    let result = await db.updatePage({
      id: this.getId(),
      image: this.getImage(),
      content: this.getContent(),
      position: this.getPosition()
    })

    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new PagesDB()
    let result = await db.deletePage(this.getId())

    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish(lesson_slug) {
    if(this.getId() == "") return await this.create(lesson_slug)
    else return await this.update()
  }

  load() {}

  loadLesson() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data["id"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("image") && (typeof data["image"] != "string" && data["image"] != null)) {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }
    if(keys.includes("content") && typeof data["content"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "content is not a string. Aborted")
      return
    } 


    // loading data
    if(keys.includes('lesson')) {
      this.lesson.buildFromExport(data['lesson'])
      delete data['lesson']
    }

    Object.assign(this, data)
    this.updateInfo()
  }
}

export default PageController