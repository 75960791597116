import React, { useState, Component } from 'react'
import PropTypes from 'prop-types';
import { confirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';

function EditCell(props) {
  let item = props.item
  let editFunction = props.editFunction
  let removeFunction = props.removeFunction

  const [loading, setLoading] = useState(false)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await removeFunction(item)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Button
      icon="pi pi-file-edit"
      text
      onClick={() => editFunction(item)}/>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}
      loading={loading}/>
    </>
  )
}

EditCell.propTypes = {
  item: PropTypes.object.isRequired,
  editFunction: PropTypes.func.isRequired,
  removeFunction: PropTypes.func.isRequired
}

export default EditCell