import React, { useRef, useEffect } from 'react'
import { FileUpload } from 'primereact/fileupload';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import "./style.css"

function ImagePicker(props) {
  let image_link = props.image_link
  let setImageLink = props.setImageLink
  const imagePicker = useRef(null)

  function upload(data) {
    let new_file = data.originalEvent.target.files[0]
    let blob_url = URL.createObjectURL(new_file)
    setImageLink(blob_url)
  }

  useEffect(() => {
    if(image_link) {
      const file = new File([], "_empty_");
      imagePicker.current.setFiles([file])
    }
  }, [])

  return (
    <div className={"image_picker"}>
      <FileUpload
        ref={imagePicker}
        accept="image/*" 
        chooseLabel="Scegli" 
        uploadOptions={{style: {display: "none"}}}
        cancelOptions={{ style: {display: "none"}}}
        onSelect={upload}
        itemTemplate={() => <CustomItemTemplate onRemove={() => {setImageLink(""); imagePicker.current.setFiles([])}} url={image_link} />}
        emptyTemplate={<p>Trascina qui un immagine oppure clicca il tatso "Scegli"</p>}/><br/>
    </div>
  )
}

function CustomItemTemplate(props) {
  let onRemove = props.onRemove
  let url = props.url

  return (
    <div className="grid justify-content-between align-items-center">
      <img src={url} className="w-2"/>
      {/* <Button onClick={onRemove} label="Elimina" severity="danger" className="h-3rem" /> */}
    </div>
  )
}

ImagePicker.propTypes = {
  image_link: PropTypes.string.isRequired,
  setImageLink: PropTypes.func.isRequired,
}


export default ImagePicker