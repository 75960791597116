import React, { useEffect, useState, useContext } from 'react'
import TopicController from '../../../../controllers/education/quiz/topic_controller'
import TopicsListController from '../../../../controllers/education/quiz/topics_list_controller'
import { useLocation, useNavigate, useParams } from 'react-router'
import routes from '../../../../router/routes'
import { Context } from "../../../../App"
import { Button } from 'primereact/button'
import MyTable from '../../../../components/my_table/my_table'
import EditCell from '../../../../components/edit_cell'
import Popup from '../../../../components/popup'
import PopupFooter from '../../../../components/popup_footer'
import { InputText } from 'primereact/inputtext'
import { toInteger } from 'lodash'

function ArgumentPage() {
  const [selectedArgument, setSelectedArgument] = useState(new TopicController())
  const [argumentsList, setArgumentsList] = useState(new TopicsListController())
  const [popupVisible, setPopupVisible] = useState(false)

  let params = useParams()
  let navigate = useNavigate()
  let { state } = useLocation()
  let showMessage = useContext(Context)

  let subject = params.subject
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / argumentsList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    if(selectedArgument.getName() == "") message.push("Nome")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function laoding() {
    let result = await argumentsList.load(subject, page)
    if(result.error) showMessage(true, result.message)
  }

  async function createArgument() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedArgument.publish(subject)
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        selectedArgument.reset()
        argumentsList.reset()
        laoding()
        setPopupVisible(false)
      }
    }
  }

  async function deleteArgument(argument) {
    let result = await argument.delete()
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Operazione completata")
      argumentsList.reset()
      laoding()
    }
  }

  function editFunction(argument) {
    selectedArgument.buildFromExport(argument.export())
    setPopupVisible(true)
  }

  useEffect(() => {
    if(subject == undefined) navigate(routes.quiz)
    else {
      selectedArgument.setState(setSelectedArgument)
      argumentsList.setState(setArgumentsList)
      laoding()
    }
  }, [page])

  return (
    <div id={"quiz_argument"}>
      <div className="flex justify-content-between">
        <h1>Education - Quiz</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3>Argomenti</h3>

      <MyTable
      first={first}
      values={argumentsList.getItemsPerPage(page)}
      totalRecords={argumentsList.getTotalItems()}
      onRowSelection={({ value }) => navigate(routes.quiz_quiz + "/" + value.getId())}
      itemsPerPage={argumentsList.getPageSize()}
      columns={[
        {field: "position", header: "Posizione"},
        {field: "name", header: "Nome"},
        {body: (argument) => <EditCell item={argument} editFunction={editFunction} removeFunction={deleteArgument}/>}
      ]}
      />

      <Popup
      title={"Crea argomento"}
      visible={popupVisible}
      setVisible={setPopupVisible}
      footer={<PopupFooter saveFunction={createArgument} />}
      closeFunction={() => selectedArgument.reset()}
      >
        <label>Nome</label>
        <InputText placeholder={"Nome"} className="w-full mt-2" value={selectedArgument.getName()} onChange={(e) => selectedArgument.setName(e.target.value)} /><br/><br/>
        <label>Posizione</label>
        <InputText keyfilter={"int"} placeholder={"Posizione"} className="w-full mt-2" value={selectedArgument.getPosition()} onChange={(e) => selectedArgument.setPosition(toInteger(e.target.value))} />
      </Popup>
    </div>
  )
}

export default ArgumentPage