import React, { useEffect, useState, useContext } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router'
import {useHistory} from "react-router-dom"
import Menu from '../../components/menu'
import { Divider } from 'primereact/divider';
import UserController from '../../controllers/user_controller';
import { Context } from "../../App"
import "./style.css"
import routes from '../../router/routes';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';

function Home() {
  const [user, setUser] = useState(new UserController())
  const [isLogged, setIsLogged] = useState(false)
  let location = useLocation()
  let navigate = useNavigate()

  useEffect(() => {
    async function loading() {
      let result = await user.load()
      if(result.error) navigate(routes.login)
      else {
        setIsLogged(true)
        if(location.pathname == "/") navigate(routes.news)
      }
    }

    user.setState(setUser)
    loading()
  }, [])
  
  return (
    <div id={"home_page"}>
      {
        !isLogged ? 
        <div className="grid h-screen align-content-center">
          <ProgressSpinner animationDuration="3"/> 
        </div> :
        user.canI("access") ? 
          <>
            <div className="grid col-12 md:col-12 card-container">
              <div id="menu_container" className="col-12 md:col-4 lg:col-3 p-0 md:flex md:justify-content-end">
                <div>
                  <h1 className="pl-4">SIL</h1>
                  <Divider />
                  <Menu logout={() => user.logout()} user={user} />
                </div>
              </div>  
              <div className="col-12 md:col-8 lg:col-9 pl-4">
                <Outlet />
                <br/>
              </div>
            </div>
            <br/>
          </> : 
          <div className="h-screen grid align-content-center justify-content-center">
            <Card>
              <h1>Non puoi  accedere</h1>
            </Card>
          </div>
      }
      
    </div>
  )
}

export default Home