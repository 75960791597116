import { Card } from 'primereact/card'
import React, { useEffect, useState } from 'react'
import { Menu as MenuPrime } from 'primereact/menu';
import {useWindowInfo} from 'react-window-info-hook'
import { Accordion, AccordionTab } from 'primereact/accordion';
import routes from '../../router/routes';
import { useLocation, useNavigate } from 'react-router';
import "./style.css"

function Menu(props) {
  const [activeIndex, setActiveIndex] = useState(1)

  let windowInfo = useWindowInfo()
  let logout = props.logout
  let user = props.user

  useEffect(() => {
    console.log(activeIndex)
  }, [])
  

  return (
    <div id={"menu"} className="">
      {
        windowInfo.mobile ?
        <div id="menu_mobile">
          <Accordion activeIndex={activeIndex} onTabChange={(e) => {/** to keep */}}>
            <AccordionTab
            header="Menu"
            onClick={() => setActiveIndex(activeIndex == 1 ? 0 : 1)}>
              <Items logout={logout} user={user}/>
            </AccordionTab>
          </Accordion>
          {/* <Items /> */}
        </div> :
        <div id="menu_desktop"><Items logout={logout} user={user}/></div>
      }
    </div>
  )
}

function Items(props) {
  let location = useLocation()
  let navigate = useNavigate()
  let logout = props.logout
  let user = props.user
  
  let model = [
    // {
    //   label: "News",
    //   items: [
    //     {label: "News", icon: "", command: () => navigate(routes.news), path: routes.news},
    //   ]
    // },
    {
      label: "Education",
      icon: "",
      items: [
        {label: "Impara", icon: "", command: () => navigate(routes.learn), path: routes.learn},
        {label: "Quiz", icon: "", command: () => navigate(routes.quiz), path: routes.quiz},
        {label: "School Challenge", icon: "", command: () => navigate(routes.school_challenge), path: routes.school_challenge},
      ]
    },
    {
      label: "Orientamento",
      icon: "",
      items: [
        {label: "Lavorativo", icon: "", command: () => navigate(routes.orientation_work), path: routes.orientation_work},
        {label: "Universitario", icon: "", command: () => navigate(routes.orientation_university), path: routes.orientation_university},
      ]
    },
    {
      label: "Utente",
      icon: "",
      items: [
        {label: "Profilo", icon: "", command: () => {}},
        {label: "Esci", icon: "", command: () => {
          logout()
          navigate(routes.login)
        }}
      ]
    }
  ]

  function generateModel() {
    if(user.canI("access")) {
      if(!user.canI("news")) delete model[0]
      model = model.map((item) => {
        if(Object.keys(item).includes("items")) {
          item.items.map((child) => child['className'] = child['path'] != undefined ? (location.pathname.includes(child['path']) ? "selected" : "") : "")
        }
        return item
      })
      return model
    } else {
      return []
    }
  }

  return (
    <MenuPrime id="menu" model={generateModel()} />
  )
}


export default Menu