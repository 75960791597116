import MyComponent from "../../base_components/my_component";
import TopicController from "./topic_controller";
import UniversityDB from "../database/orientation/university";

class TopicsListController extends MyComponent {
  constructor() {
    super()
    this.list = []
    this.page = 1
    this.total_items = 0
    this.items_per_page = 2
    this.page_index_link = {}
    this.max_page = Infinity
    this.university = true
  }

  // setter
  setList(value, auto_update = true) {
      if(!Array.isArray(value)) {
        console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
        return
      }

      this.list = value 
      if(auto_update) this.updateInfo()
  }

  setTotalItems(value, auto_update = true) {
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "totalItems is not a number. Aborted")
      return
    }

    this.total_items = value 
    if(auto_update) this.updateInfo()
  }

  setPage(value, auto_update = true) {
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return
    }

    this.page = value 
    if(auto_update) this.updateInfo()
  }

  setWork() {
    this.university = false
    this.updateInfo()
  }

  setUniversity() {
    this.university = true
    this.updateInfo()
  }

  // getter
  getList() { return this.list }
  getTotalItems() { return this.total_items }
  getItemsPerPage() { return this.items_per_page }
  getItemsWindow(page) {
    let starting_index = this.page_index_link[page]?.start
    let ending_index = this.page_index_link[page]?.end
    let window = this.list.slice(starting_index, starting_index + ending_index)
    return window
  }

  anotherPage() { return this.page != null }

  // API
  async load(page = this.page) {
    let db = new UniversityDB(this.university)
    if(!Object.keys(this.page_index_link).includes(page.toString()) && this.max_page > page) {
      let result = await db.getAllArguments(page, this.items_per_page)
      if(result.error) {
        // errore
        return {
          error: true,
          message: "Qualcosa è andato storto..."
        }
      } else {
        let items = result.response.items
        let navigation_info = result.response.navigation
        if(navigation_info.next == null) this.max_page = page
        this.setTotalItems(result.response.total_items, false)
  
        let index_saved = false
        for(let item of items) {
          item['id'] = item['slug']
          item['partner_name'] = item['partner']['name']
          item['partner_link'] = item['partner']['link']
          delete item['partner']
          delete item['slug']
          item['stuff'] = {}
          item['stuff']['list'] = item['content'].map(stuff => {
            stuff['id'] = stuff['slug']
            stuff['content'] = stuff['text']
            stuff['call_to_action'] = stuff['link']
            delete stuff['slug']
            delete stuff['text']
            delete stuff['link']
            return stuff
          })
          delete item['content']
          if(!index_saved) {
            this.page_index_link[page] = {start: this.list.length, end: items.length}
            index_saved = true
          }
          this.addTopic(item, false)
        }
  
        this.updateInfo()
        return {error: false}
      }
    }
  }

  // management
  addTopic(topic, auto_update = true) {
    if(Array.isArray(topic)) {
      console.warn(`[ ${this.constructor.name} ]`, "topic is a list. Aborted")
      return
    } else if(topic instanceof TopicController) {
      if(this.university) topic.setUniversity()
      else topic.setWork()
      this.list.push(topic)
    } else if(typeof topic == "object") {
      // creating topic instance
      let newtopic = new TopicController()
      newtopic.setOverrideState((() => this.updateInfo()).bind(this))
      newtopic.buildFromExport(topic)   // all the correctness aspects are inside the function
      if(this.university) newtopic.setUniversity()
      else newtopic.setWork()
      this.list.push(newtopic)
    }

    if(auto_update) this.updateInfo()
  }

  deleteTopic(topic) {
    if(typeof topic == "string") {
      // topic is the topic id
      let topicId = topic
      topic = this.list.find(item => item.getId() == topicId)
      let index = this.list.indexOf(topic)
      this.list.splice(index, 1)
    } else if(topic instanceof TopicController) {
      // topic is an instance of TopicController
      let index = this.list.indexOf(topic)
      this.list.splice(index, 1)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "topic is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type ckeck
    let keys = Object.keys(data)
    if(keys.includes("list") && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
      return
    }
    for(let element of data['list']) {
      if(!(element instanceof TopicController)) {
        console.warn(`[ ${this.constructor.name} ]`, "an element of the list is not a TopicController instance. Aborted")
        return
      }
    }

    // loading data
    if(keys.includes('list')) {
      for(let element of data['list']) this.addTopic(element)
    }

    this.updateInfo()
  }
}

export default TopicsListController