import MyComponent from "../../../base_components/my_component";
import ArgumentDB from "../../database/education/school_challenge/argument";
import QuizListController from "./quiz_list_controller";

class TopicController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.image = ""
    this.name = ""
    this.description = ""
    this.quiz = new QuizListController()
    this.quiz.setOverrideState((() => this.updateInfo()).bind(this))
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setImage(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }

    this.image = value
    if(auto_update) this.updateInfo()
  }

  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    if(auto_update) this.updateInfo()
  }

  setDescription(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
      return
    }

    this.description = value
    if(auto_update) this.updateInfo()
  }

  setQuiz(value, auto_update = true) {
    // type check
    if(!(value instanceof QuizListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "quiz is not a QuizListController instance. Aborted")
      return
    }

    this.quiz = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getImage() { return this.image }
  getName() { return this.name }
  getDescription() { return this.description }
  getQuiz() { return this.quiz }

  // API
  async create() {
    let db = new ArgumentDB()
    let result = await db.createArgument({
      image: this.getImage(),
      name: this.getName(),
      description: this.getDescription()
    })

    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async update() {
    let db = new ArgumentDB()
    let result = await db.updateArgument({
      id: this.getId(),
      image: this.getImage(),
      name: this.getName(),
      description: this.getDescription()
    })

    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new ArgumentDB()
    let result = await db.deleteArgument(this.getId())

    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish() {
    if(this.getId() == "") return await this.create()
    else return await this.update()
  }

  load() {}

  loadQuiz() {}

  linkQuiz() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data["id"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("image") && typeof data["image"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }
    if(keys.includes("name") && typeof data["name"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    if(keys.includes("description") && typeof data["description"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
      return
    }


    // loading data
    if(keys.includes('quiz')) {
      this.quiz.buildFromExport(data['quiz'])
      delete data['quiz']
    }
    
    Object.assign(this, data)
    this.updateInfo()
  }
}

export default TopicController