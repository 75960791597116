import Database from "../../database";

class PagesDB {
  constructor() {
    this.db = new Database()
  }

  typeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('image')) {
      if(typeof data['image'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('content')) {
      if(typeof data['content'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "content is not a string. Aborted")
        return false
      }
    }

    return true
  }

  async getAllPages(lesson_slug, page_number, page_size) {
    if(typeof lesson_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "lesson_slug is not a string. Aborted")
      return false
    }
    if(typeof page_size != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page_size is not a number. Aborted")
      return false
    }
    if(typeof page_number != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page_number is not a number. Aborted")
      return false
    }
    let result = await this.db.get({
      path: `/gamification/learn/lesson/${lesson_slug}/pages?page_number=${page_number}&page_size=${page_size}`
    })

    return result
  }

  async createPage(data, lesson_slug) {
    if(typeof lesson_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "lesson_slug is not a string. Aborted")
      return false
    }

    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let imageFile = await fetch(data['image'])
      .then((data) => data.blob())
      .then(blob => {
        const file = new File([blob], 'image.png', { type: 'image/png' });
        return file
      })
      let formData = new FormData()
      if(data['image'] != "") formData.append("image", imageFile)
      formData.append("content", data['content'])
      formData.append("order", data['position'])

      let result = await this.db.post({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/gamification/learn/lesson/${lesson_slug}/pages`,
        data: formData
      })

      return result
    }

  }

  async updatePage(data) {
    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let formData = new FormData()
      if(data['image'].substring(0,4) == "blob" && data['image'] != "") {
        let imageFile = await fetch(data['image'])
        .then((data) => data.blob())
        .then(blob => {
          const file = new File([blob], 'image.png', { type: 'image/png' });
          return file
        })
        formData.append("image", imageFile)
      }
      formData.append("content", data['content'])
      formData.append("order", data['position'])

      let result = await this.db.put({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/gamification/learn/page/${data['id']}`,
        data: formData
      })

      return result
    }
  }

  async deletePage(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "slug is not a number. Aborted")
      return false
    }

    let result = await this.db.delete({
      path: `/gamification/learn/page/${slug}`
    })
    return result
  }
}

export default PagesDB