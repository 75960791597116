import Database from "../../database";

class MacroArgumentDB {
  constructor() {
    this.db = new Database()
  }

  typeChech(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('name')) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('position')) {
      if(typeof data['position'] != "number") {
        console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
        return false
      }
    }
    return true
  }

  async getAllMAcroArguments(page_number, page_size, subject_slug) {
    let result = await this.db.get({
      path: `/gamification/learn/subject/${subject_slug}/macro_arguments?page_number=${page_number}&page_size=${page_size}`,
    })

    return result
  }

  async createMacroArgument(data, subjectSlug) {
    if(typeof subjectSlug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "subjectSlug is not a string. Aborted")
      return false
    }

    let isCorrect = this.typeChech(data)
    if(isCorrect) {
      let result = await this.db.post({
        path: `/gamification/learn/subject/${subjectSlug}/macro_arguments`,
        data: {
          name: data['name'],
          order: data['position']
        }
      })
      return result
    }
  }
  
  async updateMacroArgument(data) {
    let isCorrect = this.typeChech(data)
    if(isCorrect) {
      let result = await this.db.put({
        path: "/gamification/learn/macro_argument/" + data['id'],
        data: {
          name: data['name'],
          order: data['position']
        }
      })
      return result
    }
  }

  async deleteMacroArgument(slug) {
    let result = await this.db.delete({
      path: "/gamification/learn/macro_argument/" + slug
    })

    return result
  }
}

export default MacroArgumentDB