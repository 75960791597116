import axios from 'axios'
import React, {useEffect} from 'react'
import { useNavigate } from 'react-router'
import routes from '../../router/routes'
import "./style.css"

function News() {
  let navigate = useNavigate()

  useEffect(() => {
    // TODO: to change
    navigate(routes.learn)
  }, [])
  
  return (
    <div id="news_page">
      <h1>News</h1>

    </div>
  )
}

export default News