import React, { useEffect, useState, useContext } from 'react'
import { Card } from "primereact/card"
import { Button } from 'primereact/button'
import { useLocation, useNavigate } from 'react-router'
import Popup from '../../../../components/popup'
import ImagePicker from '../../../../components/image_picker'
import { InputText } from "primereact/inputtext"
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Divider } from 'primereact/divider';
import routes from "../../../../router/routes"
import "./style.css"
import SubjectListController from '../../../../controllers/education/learn/subject_list_constroller'
import SubjectController from '../../../../controllers/education/learn/subject_controller'
import { Context } from "../../../../App"
import MyTable from '../../../../components/my_table/my_table'
import { Avatar } from 'primereact/avatar'

function LearnEducation() {
  const [openPopup, setOpenPopup] = useState(false)
  const [subjectList, setSubjectList] = useState(new SubjectListController())
  const [selectedSubject, setSelectedSubject] = useState(new SubjectController())
  const showMessage = useContext(Context)

  let navigate = useNavigate()
  let { state } = useLocation()
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / subjectList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    if(selectedSubject.getImage() == "") message.push("Immagine")
    if(selectedSubject.getName() == "") message.push("Nome")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading(page) {
    let result = await subjectList.load(page)
    if(result.error) showMessage(true, result.message)
    let lastPage = Math.floor(subjectList.getTotalItems() / subjectList.getPageSize() + 1)
    result = await subjectList.load(lastPage)
    if(result.error) showMessage(true, result.message)
  }

  function deleteSubject() {
    setOpenPopup(false)
  }

  async function saveSubject() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedSubject.publish()
      if(result.error) showMessage(true, result.message)
      else {
        subjectList.reset()
        loading(page)
        showMessage(false, "Operazione completata")
        setOpenPopup(false)
        selectedSubject.reset()
      }
    }
  }

  function editSubject(subject) {
    selectedSubject.buildFromExport(subject.export())
    setOpenPopup(true)
  }

  function closeFunction() {
    let newSubject = new SubjectController()
    newSubject.setState(setSelectedSubject)
    setSelectedSubject(newSubject)
  }

  useEffect(() => {
    selectedSubject.setState(setSelectedSubject)
    subjectList.setState(setSubjectList)
    loading(page)
  }, [page])
  

  return (
    <div id="learn_education_page">
      <div className="flex justify-content-between">
        <h1>Education - Impara</h1>
        <div className="flex align-items-center"><Button onClick={() => setOpenPopup(true)} label="Crea" /></div>
      </div>

      <Popup
      visible={openPopup}
      setVisible={setOpenPopup}
      title="Aggiungi Materia"
      footer={<PopupFooter saveFunction={saveSubject} removeFunction={deleteSubject}/>}
      closeFunction={() => closeFunction()}>
        <label>Immagine</label>
        <div className="mt-2">
          <ImagePicker image_link={selectedSubject.getImage()} setImageLink={(image_link) => selectedSubject.setImage(image_link)}/>
        </div>
        <label>Nome</label>
        <InputText placeholder="Nome" className="w-full mt-2" value={selectedSubject.getName()} onChange={(e) => selectedSubject.setName(e.target.value)}/>
      </Popup>
      
      <h3>Meterie</h3>
      <Divider />
      
      <MyTable 
      first={first}
      values={subjectList.getItemsWindow(page)}
      totalRecords={subjectList.getTotalItems()}
      itemsPerPage={subjectList.getPageSize()}
      onRowSelection={({value}) => navigate(routes.learn_macro_argument + `/${value.getId()}`)}
      columns={[
        {field: "image", header: "Immagine", body: (subject) => <Avatar image={subject.getImage()} shape="circle" size="large"/>},
        {field: "name", header: "Nome"},
        {body: (...props) => <EditCell {...props} page={page} editSubject={editSubject} loading={loading} subjectList={subjectList}/>, align: "right"}
      ]}
      />
    </div>
  )
}

function EditCell(props) {
  let subject = props[0]
  let subjectList = props['subjectList']
  let loadingFunction = props['loading']
  let page = props['page']
  let editSubject = props['editSubject']
  const showMessage = useContext(Context)
  const [loading, setLoading] = useState(false)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        let result = await subject.delete()
        if(result.error) showMessage(true, result.message)
        else {
          subjectList.deleteSubject(subject.getId())
          subjectList.reset()
          loadingFunction(page)
          showMessage(false, "Eliminazione completata")
        }
        setLoading(false)
      }
    })
  }
  
  return (
    <div className="edit_cell">
      <Button
      icon="pi pi-file-edit"
      text
      onClick={() => editSubject(subject)}/>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}
      loading={loading}/>
    </div>
  )
}

function PopupFooter(props) {
  let removeFunction = props?.removeFunction
  let saveFunction = props?.saveFunction
  const [saveLoading, setSaveLoading] = useState(false)

  function deleteButtonFunction(e) {
    confirmPopup({
      target: e.currentTarget,
      message: "Sei sicuro di voler eliminare?",
      accept: removeFunction
    })
  }

  function saveButtonFunction(e) {
    confirmPopup({
      target: e.currentTarget,
      message: "Sei sicuro di voler salvare?",
      accept: async () => {
        setSaveLoading(true)
        await saveFunction()
        setSaveLoading(false)
      }
    })
  }

  return (
    <div>
      <ConfirmPopup />
      <Button label="Salva" onClick={saveButtonFunction} loading={saveLoading}/>
    </div>
  )
}

export default LearnEducation