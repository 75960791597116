class MyComponent {
  constructor() {
    this.state = undefined
    this.overrideState = undefined
  }

  // sets the graphical state function
  setState(state) {
    // type check
    if(typeof state != "function") {
      console.warn(`[ ${this.constructor.name} ]`, "state is not a function. Aborted")
      return
    }

    this.state = state
    // this.updateInfo()
  }

  setOverrideState(overrideState) {
    // type check
    if(typeof overrideState != "function") {
      console.warn(`[ ${this.constructor.name} ]`, "overrideState is not a function. Aborted")
      return
    }

    this.overrideState = overrideState
    // this.updateInfo()
  }

  // used to update the graphical interface
  updateInfo() {
    let newInstance = null
    if(this.state != undefined) {
      newInstance = new this.constructor()
      Object.assign(newInstance, this)
      this.state(newInstance)
      // await this.state(this)
    } else if(this.overrideState != undefined) this.overrideState()
    return newInstance
  }

  // management
  export() {
    return JSON.parse(JSON.stringify(this))
  }

  toString() {
    return JSON.stringify(this)
  }

  reset() {
    let newInstance = new this.constructor()
    newInstance.setState(this.state)
    Object.assign(this, newInstance)
    this.updateInfo()
  }

  __isValidObject(data) {   // complete the implementation on the parent class
    // type check of data
    // if data is an object stringified
    try {
      if(typeof data == "string") return data = JSON.parse(data)
    } catch (error) {
      // cannot convert data to Object
      console.warn(`[ ${this.constructor.name} ]`, "data is not in the correct form. Aborted")
      return false
    }
    if(typeof data != "object" || Array.isArray(data) || data == null) {
      console.warn(`[ ${this.constructor.name} ]`, "data is not an object. Aborted")
      return false
    }

    return data
  }
}

export default MyComponent