import { findByAltText } from "@testing-library/react";
import MyComponent from "../../../base_components/my_component";
import PagesDB from "../../database/education/learn/pages";
import PageController from "./page_controller";

class PagesListController extends MyComponent {
  constructor() {
    super()
    this.list = []
    this.page_size = 10
    this.total_items = 0
    this.page_index_realtion = {}
  }

  // setter
  setList(value, auto_update = true) {
    if(!Array.isArray(value)) {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return  
    }

    this.list = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getList() { return this.list }
  getPageSize() { return this.page_size}
  getTotalItems() { return this.total_items}

  getItemsPerWindow(page) {
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return  
    }

    if(Object.keys(this.page_index_realtion).includes(page.toString())) {
      let start = this.page_index_realtion[page]['start']
      let end = this.page_index_realtion[page]['end']
      let window = this.list.slice(start, end)
      return window
    } return []
  }

  // API
  async load(lesson_slug, page) {
    let result = {error: false}
    if(!Object.keys(this.page_index_realtion).includes(page.toString())) {
      let db = new PagesDB()
      result = await db.getAllPages(lesson_slug, page, this.page_size)
      if(result.error) result['message'] = "Qualcosa è andato storto..."
      else {
        let items = result.response.items
        this.total_items = result.response.total_items
        this.page_index_realtion[page] = {start: this.list.length, end: this.list.length + items.length}

        for(let data of items) {
          this.addPage({
            id: data['slug'],
            image: data['image'],
            content: data['content'],
            position: data['order']
          }, false)
        }

        this.updateInfo()
      }
    }
    return result
  }

  // management
  addPage(page, auto_update) {
    if(Array.isArray(page)) {
      console.warn(`[ ${this.constructor.name} ]`, "page is a list. Aborted")
      return
    } else if(page instanceof PageController) {
      this.list.push(page)
    } else if(typeof page == "object") {
      // creating page instance
      let new_page = new PageController()
      new_page.setOverrideState((() => this.updateInfo()).bind(this))
      new_page.buildFromExport(page)   // all the correctness aspects are inside the function
      this.list.push(new_page)
    }

    if(auto_update) this.updateInfo()
  }

  deletePage(page) {
    if(typeof page == "string") {
      // page is the page id
      let page_id = page
      page = this.list.find(item => item.getId() == page_id)
      let index = this.list.indexOf(page)
      this.list.splice(index, 1)
    } else if(page instanceof PageController) {
      // page is an instance of PageController
      let index = this.list.indexOf(page)
      this.list.splice(index, 1)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "page is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof PageController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item of the list is not a PageController instance. Aborted")
          return
        }
      }
    }

    this.updateInfo()
  }
  
}

export default PagesListController