import React, { useState } from 'react'
import PropTypes from "prop-types"
import { confirmPopup } from 'primereact/confirmpopup'
import { Button } from 'primereact/button'

function PopupFooter(props) {
  let saveFunction = props.saveFunction
  const [loading, setLoading] = useState(false)

  function save(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await saveFunction()
        setLoading(false)
      }
    })
  }
  return (
    <div>
      <Button label="Salva" loading={loading} onClick={save}/>
    </div>
  )
}
PopupFooter.propTypes = {
  saveFunction: PropTypes.func.isRequired
}

export default PopupFooter