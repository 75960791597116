import { createBrowserRouter } from "react-router-dom";
import home_route from "../router/home"
import login_route from "../router/login"
import prova_route from "../router/prova"

export const base_route = {
  errorElement: <p>Erroreeee</p>
}

let router = createBrowserRouter([
  {...base_route, ...home_route},
  {...base_route, ...login_route},
  {...base_route, ...prova_route}
])

export default router