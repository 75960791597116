let routes = {
  home: "/",
  news: "/news",
  login: "/login",
  // orientation
  orientation_university: "/orientation/university",
  orientation_work: "/orientation/work",
  editor_orientation: "/orientation/editor",
  // education
  learn: "/education/learn",
  learn_macro_argument: "/education/learn/macro_argument",
  learn_argument: "/education/learn/argument",
  learn_lessons: "/education/learn/lessons",
  learn_pages: "/education/learn/pages",
  quiz: "/education/quiz",
  quiz_argument: "/education/quiz/argument",
  quiz_quiz: "/education/quiz/quiz",
  quiz_question: "/education/quiz/question",
  school_challenge: "/education/school_challenge",
  school_challenge_quiz: "/education/school_challenge/quiz",
  school_challenge_question: "/education/school_challenge/question",
  prova: "/prova"
}

export default routes