import React, { useEffect, useState, useContext, useRef } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'
import routes from '../../../router/routes'
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import "./style.css"
import { Divider } from 'primereact/divider';
import ImagePicker from '../../../components/image_picker';
import TopicController from '../../../controllers/orientation/topic_controller';
import { Context } from "../../../App"
import { confirmPopup } from 'primereact/confirmpopup';
import { FileUpload } from 'primereact/fileupload';

function OrientationEditor() {
  const [topic, setTopic] = useState(new TopicController())
  const [title, setTitle] = useState("")
  const [saveLoading, setSaveLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const showMessage = useContext(Context)
  let navigate = useNavigate()
  let { state } = useLocation()
  let origin = state?.origin
  let topic_props = state?.topic

  function checkRequiredFields() {
    let message = []
    if(topic.getName() == "") message.push("Nome")
    if(topic.getPartnerName() == "") message.push("Nome Partner")
    if(topic.getPartnerLink() == "") message.push("Link Partner")
    if(topic.getDescription() == "") message.push("Descrizione")
    for(let stuff of topic.getStuff().getStuff()) {
      if(stuff.getName() == "") message.push("Nome materiale")
      if(stuff.getImage() == "") message.push("Immagine materiale")
      if(stuff.getContent() == "") message.push("Testo materiale")
      if(stuff.getCallToAction() == "") message.push("Call to action materiale")
    }
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  function publish(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        let error = checkRequiredFields()
        if(!error) {
          setSaveLoading(true)
          let result = await topic.publish()
          if(result.error) showMessage(true, result.message)
          else {
            showMessage(false, "Operazione completata")
            setTimeout(() => navigate(-1), 400)
          }
          setSaveLoading(false)
        }
      }
    })
  }

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setDeleteLoading(true)
        let result = await topic.delete()
        if(result.error) showMessage(true, result.message)
        else {
          showMessage(false, "Eliminazione riuscita")
          setTimeout(() => navigate(-1), 500)
        }
        setDeleteLoading(false)
      }
    })
  }

  async function deleteStuff(stuff) {
    topic.getStuff().deleteStuff(stuff)
  }

  useEffect(() => {
    topic.setState(setTopic)
    // checking if it has been done a good access to the page
    if(!["university", "work"].includes(origin)) navigate(routes.home)
    else setTitle(origin == "university" ? "Universitario" : "Lavorativo")
    if(origin == "university") topic.setUniversity()
    else topic.setWork()
    if(topic_props != undefined) {
      topic_props = JSON.parse(topic_props)
      topic.buildFromExport(topic_props)
    }
  }, [])
  
  return (
    <div id="orientation_editor_page">
      <div className="md:flex md:justify-content-between">
        <div className="flex">
          <div className="flex align-items-center">
            <i onClick={() => navigate(-1)} className="pi pi-arrow-circle-left" style={{ fontSize: '1.5rem' }}></i>
          </div>
          <h1 className="ml-2">Orientamento {title}</h1>
        </div>
        <div className="flex align-items-center">
          <Button loading={saveLoading} label="Salva" className="mr-2" onClick={publish}/>
          <Button loading={deleteLoading} label="Elimina" className="mr-2" onClick={remove} severity={'danger'} />
        </div>
      </div>

      <h3>Informazioni</h3>
      <label>Nome</label>
      <InputText className="w-full mt-2" name="required" placeholder="Nome argomento" value={topic.getName()} onChange={(e) => topic.setName(e.target.value)}/><br/><br/>
      <label>Nome Partner</label>
      <InputText className="w-full mt-2" name="required" placeholder="Nome partner" value={topic.getPartnerName()} onChange={(e) => topic.setPartnerName(e.target.value)}/><br/><br/>
      <label>Link Partner</label>
      <InputText className="w-full mt-2" name="required" placeholder="Link partner" value={topic.getPartnerLink()} onChange={(e) => topic.setPartnerLink(e.target.value)}/><br/><br/>
      <label>Descrizione</label>
      <InputTextarea className="w-full mt-2" name="required" placeholder="Descrizione" value={topic.getDescription()} onChange={(e) => topic.setDescription(e.target.value)} rows={5} cols={30} autoResize/><br/>


      <Divider />


      <h3>Materiale</h3>
      {
        topic.getStuff().getStuff().map((stuff) =>
        <Accordion>
          <AccordionTab header={<AccordionHeader title={stuff.getName()} deleteFunction={() => deleteStuff(stuff)}/>}>
            <label>Nome</label>
            <InputText className="w-full mt-2" placeholder="Nome" name="required" value={stuff.getName()} onChange={(e) => stuff.setName(e.target.value)}/><br/><br/>
            <label>Immagine</label>
            <div className="mt-2">
              <ImagePicker image_link={stuff.getImage()} name="required" setImageLink={(image) => stuff.setImage(image)}/>
            </div>
            <label>Testo</label>
            <InputTextarea className="w-full mt-2" placeholder="Testo" name="required" rows={5} cols={30} autoResize value={stuff.getContent()} onChange={(e) => stuff.setContent(e.target.value)}/><br/><br/>
            <label>Call to action</label>
            <InputText className="w-full mt-2" placeholder="Link call to action" name="required" value={stuff.getCallToAction()} onChange={(e) => stuff.setCallToAction(e.target.value)} /><br/><br/>
          </AccordionTab>
        </Accordion>)
      }

      <br/>

      <Button icon={<i className="pi pi-plus-circle"></i>} label="Aggiungi" onClick={() => topic.getStuff().addStuff()}/>
    </div>
  )
}

function AccordionHeader(props) {
  let title = props.title
  let deleteFunction = props.deleteFunction
  
  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: deleteFunction
    })
  }

  return (
    <div className="flex justify-content-between">
      <h3>{title}</h3>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}/>
    </div>
  )
}

export default OrientationEditor