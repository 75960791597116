import React, { useEffect, useState, useContext } from 'react'
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import UserController from '../../controllers/user_controller';
import { Context } from "../../App"
import "./style.css"
import { useNavigate } from 'react-router';
import routes from '../../router/routes';
import { Password } from 'primereact/password';

function Login() {
  const [user, setUser] = useState(new UserController())
  const [password, setPassword] = useState("")
  const showMessage = useContext(Context)
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()

  async function login() {
    setLoading(true)
    let result = await user.login(password)
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Benvenuto!")
      navigate(routes.news)
    }
    setLoading(false)
  }

  useEffect(() => {
    user.setState(setUser)
    if(window.sessionStorage.getItem("access_token")) navigate(routes.news)
  }, [])

  return (
    <div id={"login_page"} className="p-0 m-0 grid align-items-center justify-content-center ">
      <Card className="text-center grid md:align-items-center justify-content-center login_card">
        <h1 className="p-card-title mb-4">Accedi</h1>
        <InputText
        type="email"
        placeholder="Email"
        className="mb-3 w-full"
        value={user.getEmail()} 
        onChange={(e) => user.setEmail(e.target.value)}
        onKeyDown={(e) => {if(e.code == "Enter") login()}}/>
        <br/>
        <Password 
        toggleMask 
        feedback={false} 
        type="password" 
        placeholder="Password" 
        className="mb-3"
        value={password} 
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {if(e.code == "Enter") login()}}/>
        <br/>
        <Button label="Accedi" onClick={login} loading={loading}/>
      </Card>
    </div>
  )
}

export default Login