import Databse from "../../database"

class SubjectDB {
  constructor() {
    this.db = new Databse()
  }

  typeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes("id")) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes("name")) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }
    }
    if(keys.includes("image")) {
      if(typeof data['image'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
        return false
      }
    }
    return true
  }

  async getAllSubjects(page_number, page_size=50) {
    let result = await this.db.get({
      path: `/gamification/learn/subjects?page_number=${page_number}&page_size=${page_size}`
    })
    return result
  }

  async createSubject(data) {
    let is_correct = this.typeCheck(data)
    if(is_correct) {
      // make request
      
      let formData = new FormData()
      formData.append("name", data['name'])
      let imageFile = await fetch(data['image'])
        .then((data) => data.blob())
        .then(blob => {
          const file = new File([blob], 'image.png', { type: 'image/png' });
          return file
        })
      formData.append("image", imageFile)
      let result = await this.db.post({
        path: "/gamification/learn/subjects",
        data: formData,
        headers: {"Content-Type": "multipart/form-data"}
      })
      return result
    }
  }

  async deleteSubject(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return 
    }

    let result = await this.db.delete({
      path: "/gamification/learn/subject/" + slug
    })

    return result
  }

  async updateSubject(data) {
    let is_correct = this.typeCheck(data)

    // make request
    let formData = new FormData()
    formData.append("name", data['name'])

    if(data['image'].substring(0, 4) == "blob") {
      let imageFile = await fetch(data['image'])
      .then((data) => data.blob())
      .then(blob => {
        const file = new File([blob], 'image.png', { type: 'image/png' });
        return file
      })
      formData.append("image", imageFile)
    }

    if(is_correct) {
      let result = await this.db.put({
        path: "/gamification/learn/subject/" + data['id'],
        data: formData,
        headers: {"Content-Type": "multipart/form-data"}
      })
      return result
    }
  }
}

export default SubjectDB