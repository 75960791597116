import Database from "../../database";

class TopicDB {
  constructor() {
    this.db = new Database()
  }

  typeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }  
    }
    if(keys.includes('image')) {
      if(typeof data['image'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
        return false
      }  
    }
    if(keys.includes('title')) {
      if(typeof data['title'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "title is not a string. Aborted")
        return false
      }  
    }
    if(keys.includes('position')) {
      if(typeof data['position'] != "number") {
        console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
        return false
      }  
    }
    if(keys.includes('description')) {
      if(typeof data['description'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
        return false
      }  
    }

    return true
  }

  async getAllTopics(macro_argument_slug, page, page_size) {
    let result = await this.db.get({
      path: `/gamification/learn/macro_argument/${macro_argument_slug}/arguments?page_number=${page}&page_size=${page_size}`
    })
    return result
  }

  async createTopic(data, macro_argument) {
    if(typeof macro_argument != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "macro-argument is not a string. Aborted")
      return
    }

    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let formData = new FormData()
      if(data['image'] != undefined) {
        let imageFile = await fetch(data['image'])
          .then((data) => data.blob())
          .then(blob => {
            const file = new File([blob], 'image.png', { type: 'image/png' });
            return file
          })
        formData.append('image', imageFile)
      }
      formData.append('name', data['title'])
      formData.append('order', data['position'])
      formData.append('description', data['description'])

      let result = this.db.post({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/gamification/learn/macro_argument/${macro_argument}/arguments`,
        data: formData
      })

      return result
    }
  }

  async updateTopic(data) {
    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let formData = new FormData()
      if(data['image'] != undefined) {
        if(data['image'].substring(0,4) == "blob") {
          let imageFile = await fetch(data['image'])
          .then((data) => data.blob())
          .then(blob => {
            const file = new File([blob], 'image.png', { type: 'image/png' });
            return file
          })
          formData.append('image', imageFile)
        }
      }
      formData.append('name', data['title'])
      formData.append('order', data['position'])
      formData.append('description', data['description'])

      let result = this.db.put({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/gamification/learn/argument/` + data['id'],
        data: formData
      })

      return result
    }
  }

  async deleteTopic(slug) {
    let result = await this.db.delete({
      path: "/gamification/learn/argument/" + slug
    })

    return slug
  }
}

export default TopicDB