import MyComponent from "../../../base_components/my_component";
import QuestionDB from "../../database/education/quiz/question";
import AnswersListController from "./answers_list_controller";
import QuizController from "./quiz_controller";

class QuestionController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.text = ""    
    this.image = ""    
    this.position = 0
    this.quiz = new QuizController()
    this.quiz.setOverrideState((() => this.updateInfo).bind(this))
    this.answers = []
    this.correctAnswers = []
  }
  
  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setText(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "text is not a string. Aborted")
      return
    }

    this.text = value
    if(auto_update) this.updateInfo()
  }

  setImage(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }

    this.image = value
    if(auto_update) this.updateInfo()
  }

  setPosition(value, auto_update = true) {
    // type check
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    this.position = value
    if(auto_update) this.updateInfo()
  }

  setQuiz(value, auto_update = true) {
    // type check
    if(!(value instanceof QuizController)) {
      console.warn(`[ ${this.constructor.name} ]`, "quiz is not a QuizController instance. Aborted")
      return
    }

    this.quiz = value
    if(auto_update) this.updateInfo()
  }

  setAnswers(value, auto_update = true) {
    // type check
    if(!Array.isArray(value)) {
      console.warn(`[ ${this.constructor.name} ]`, "answers is not a AnswersListController. Aborted")
      return
    }

    this.answers = value
    if(auto_update) this.updateInfo()
  }

  setCorrectAnswers(value, auto_update = true) {
    // type check
    if(!Array.isArray(value)) {
      console.warn(`[ ${this.constructor.name} ]`, "answers is not a AnswersListController. Aborted")
      return
    }

    this.correctAnswers = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getText() { return this.text }
  getImage() { return this.image }
  getPosition() { return this.position }
  getQuiz() { return this.quiz }
  getAnswers() { return this.answers }
  getCorrectAnswers() { return this.correctAnswers }

  addAnswer() {
    if(this.getAnswers().length < 4) {
      this.answers.push("")
      this.updateInfo()
    }
  }

  editAnswerContent(content, index) {
    // index is from 0 to 3
    this.answers[index] = content
    this.updateInfo()
  }

  removeAnswer(index) {
    // index is from 0 to 3
    this.answers.splice(index, 1)
    this.removeCorrectAnswer(index + 1, true)
    this.updateInfo()
  }

  addCorrectAnswer(index) {
    // index is from 1 to 4
    if(!this.correctAnswers.includes(index)) {
      this.correctAnswers.push(index)
      this.updateInfo()
    }
  }

  removeCorrectAnswer(index, item_deleted = false) {
    // index is from 1 to 4
    let indexOnArray = this.correctAnswers.indexOf(index)
    if(indexOnArray != -1) {
      this.correctAnswers.splice(indexOnArray, 1)
    }
    if(item_deleted)
      this.correctAnswers = this.correctAnswers.map(element => element > index ? element - 1 : element)
    this.updateInfo()
  }

  // API
  async create(quiz_slug) {
    let db = new QuestionDB()
    let result = await db.createQuestion({
      id: this.getId(),
      text: this.getText(),
      image: this.getImage(),
      answers: this.getAnswers(),
      correctAnswers: this.getCorrectAnswers(),
      position: this.getPosition()
    }, quiz_slug)
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }
  
  async update() {
    let db = new QuestionDB()
    let result = await db.updateQuestion({
      id: this.getId(),
      text: this.getText(),
      image: this.getImage(),
      answers: this.getAnswers(),
      correctAnswers: this.getCorrectAnswers(),
      position: this.getPosition()
    })
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new QuestionDB()
    let result = await db.deleteQuestion(this.getId())
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish(quiz_slug) {
    if(this.getId() == "") return await this.create(quiz_slug)
    else return this.update()
  }

  loadQuiz() {}

  loadAnswers() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data["id"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("text") && typeof data["text"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "text is not a string. Aborted")
      return
    } 
    if(keys.includes("image") && typeof data["image"] != "string" && data["image"] != null) {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }

    // loading data
    if(keys.includes('quiz')) {
      this.quiz.buildFromExport(data['quiz'])
      delete data['quiz']
    }
    
    Object.assign(this, data)
    this.updateInfo()
  }
}

export default QuestionController