import MyComponent from "../../../base_components/my_component";
import QuestionDB from "../../database/education/school_challenge/question";
import QuestionController from "./question_controller";

class QuestionsListController extends MyComponent {
  constructor() {
    super()
    this.list = []
    this.page_size = 10
    this.total_items = 0
    this.page_index_relation = {}
  }

  // setter
  setList(value, auto_update = true) {
    if(!Array.isArray(value)) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
      return
    }

    this.list = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getList() { return this.list }
  getPageSize() { return this.page_size}
  getTotalItems() { return this.total_items }

  getItemsPerWindow(page) {
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return
    }

    if(Object.keys(this.page_index_relation).includes(page.toString())) {
      let start = this.page_index_relation[page]['start']
      let end = this.page_index_relation[page]['end']
      let window = this.list.slice(start, end)
      return window
    } else return []
  }

  // API
  async load(quiz_slug, page) {
    let db = new QuestionDB()
    let result = { error: false }

    if(!Object.keys(this.page_index_relation).includes(page.toString())) {
      let result = await db.getAllQuestions(quiz_slug, page, this.page_size)
      if(result.error) result['message'] = "Qualcosa è andato storto..."
      else {
        let items = result.response.items
        this.total_items = result.response.total_items
        this.page_index_relation[page] = {start: this.list.length, end: this.list.length + items.length}
        for(let data of items) {
          let answers = []
          let correctAnswers = []
          if(data["answer1"] != null) answers.push(data["answer1"])
          if(data["answer2"] != null) answers.push(data["answer2"])
          if(data["answer3"] != null) answers.push(data["answer3"])
          if(data["answer4"] != null) answers.push(data["answer4"])
          if(data["isAnswer1Correct"] != null && data["isAnswer1Correct"] == "1") correctAnswers.push(1)
          if(data["isAnswer2Correct"] != null && data["isAnswer2Correct"] == "1") correctAnswers.push(2)
          if(data["isAnswer3Correct"] != null && data["isAnswer3Correct"] == "1") correctAnswers.push(3)
          if(data["isAnswer4Correct"] != null && data["isAnswer4Correct"] == "1") correctAnswers.push(4)
          
          this.addQuestion({
            id: data['slug'],
            answers: answers,
            correctAnswers: correctAnswers,
            image: data['image'],
            text: data['question'],
            position: data['order'],
          })
        }
      }
    }

    return result
  }
  
  // management
  addQuestion(question = {}, auto_update = true) {
    if(Array.isArray(question)) {
      console.warn(`[ ${this.constructor.name} ]`, "question is a list. Aborted")
      return
    } else if(question instanceof QuestionController) {
      this.list.push(question)
    } else if(typeof question == "object") {
      // creating question instance
      let newquestion = new QuestionController()
      newquestion.setOverrideState((() => this.updateInfo()).bind(this))
      newquestion.buildFromExport(question)   // all the correctness aspects are inside the function
      question = newquestion
      this.list.push(question)
    }

    if(auto_update) this.updateInfo()
    return question
  }

  deleteQuestion(question) {
    if(typeof question == "string") {
      // question is the question id
      let questionId = question
      question = this.list.find(item => item.getId() == questionId)
      let index = this.list.indexOf(question)
      this.list.splice(index, 1)
    } else if(question instanceof QuestionController) {
      // question is an instance of QuestionController
      let index = this.list.indexOf(question)
      this.list.splice(index, 1)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "question is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not in a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof QuestionController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item of the list is not in a QuestionController instance. Aborted")
          return
        }
      }
    }

    Object.assign(this, data)
    this.updateInfo()
  }
}

export default QuestionsListController