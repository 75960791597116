import MyComponent from "../../../base_components/my_component";
import QuizDB from "../../database/education/school_challenge/quiz";
import QuizController from "./quiz_controller";

class QuizListController extends MyComponent {
  constructor() {
    super()
    this.list = []
    this.page_size = 10
    this.total_items = 0
    this.page_index_relation = {}
  }

  // setter
  setList(value, auto_update = true) {
    if(!Array.isArray(value)) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
      return
    }

    this.list = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getList() { return this.list }
  getPageSize() { return this.page_size }
  getTotalItems() { return this.total_items }

  getItemsPerPage(page) {
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return
    }

    if(Object.keys(this.page_index_relation).includes(page.toString())) {
      let start = this.page_index_relation[page]['start']
      let end = this.page_index_relation[page]['end']
      let window = this.list.slice(start, end)
      return window
    } else return []
  }

  // API
  async load(argument_slug, page) {
    if(typeof argument_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "argument_slug is not a string. Aborted")
      return
    }
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return
    }

    let result = { error: false }

    if(!Object.keys(this.page_index_relation).includes(page.toString())) {
      let db = new QuizDB()
      let result = await db.getAllQuiz(argument_slug, page, this.page_size)
      if(result.error) result['message'] = "Qualcosa è andato storto"
      else {
        let items = result.response.items
        this.total_items = result.response.total_items
        this.page_index_relation[page] = {start: this.list.length, end: this.list.length + items.length}

        for(let data of items) {
          this.addQuiz({
            id: data['slug'],
            name: data['name'],
            position: data['order'],
            partner_link: data['partner_link']
          }, false)
        }

        this.updateInfo()
      }
    }

    return result
  }

  // management
  addQuiz(quiz) {
    if(Array.isArray(quiz)) {
      console.warn(`[ ${this.constructor.name} ]`, "quiz is a list. Aborted")
      return
    } else if(quiz instanceof QuizController) {
      this.list.push(quiz)
    } else if(typeof quiz == "object") {
      // creating quiz instance
      let newquiz = new QuizController()
      newquiz.setOverrideState((() => this.updateInfo()).bind(this))
      newquiz.buildFromExport(quiz)   // all the correctness aspects are inside the function
      console.log(quiz)
      this.list.push(newquiz)
    }

    this.updateInfo()
  }

  deleteQuiz(quiz) {
    if(typeof quiz == "string") {
      // quiz is the quiz id
      let quizId = quiz
      quiz = this.list.find(item => item.getId() == quizId)
      let index = this.list.indexOf(quiz)
      this.list.splice(index, 1)
    } else if(quiz instanceof QuizController) {
      // quiz is an instance of QuizController
      let index = this.list.indexOf(quiz)
      this.list.splice(index, 1)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "quiz is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }
  
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not in a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof QuizController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item of the list is not in a QuestionController instance. Aborted")
          return
        }
      }
    }

    Object.assign(this, data)
    this.updateInfo()
  }

}

export default QuizListController