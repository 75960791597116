import React, {useContext, useEffect, useState} from 'react'
import { Button } from 'primereact/button'
import "./style.css"
import MyTable from '../../../../components/my_table/my_table'
import Popup from '../../../../components/popup'
import { useLocation, useNavigate, useParams } from 'react-router'
import routes from '../../../../router/routes'
import LessonController from '../../../../controllers/education/learn/lesson_controller'
import { InputText } from 'primereact/inputtext'
import { toInteger } from 'lodash'
import LessonsListController from '../../../../controllers/education/learn/lessons_list_controller'
import { Context } from "../../../../App"
import { confirmPopup } from 'primereact/confirmpopup'

function LearnLessons() {
  const [popupVisible, setPopupVisible] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(new LessonController())
  const [lessonsList, setLessonsList] = useState(new LessonsListController())

  let navigate = useNavigate()
  let showMessage = useContext(Context)
  let { state } = useLocation()
  let params = useParams()
  
  let argument = params['argument']
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / lessonsList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    if(selectedLesson.getName() == "") message.push("Nome")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading(argument, page) {
    let result = await lessonsList.load(argument, page)
    if(result.error) showMessage(true, result.message)
  }

  function editLesson(lesson) {
    selectedLesson.buildFromExport(lesson.export())
    setPopupVisible(true)
  }

  async function removeLesson(lesson) {
    let result = await lesson.delete()
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Operazione completata")
      selectedLesson.reset()
      lessonsList.reset()
      loading(argument, page)
    }
  }

  async function saveLesson() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedLesson.publish(argument)
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        selectedLesson.reset()
        lessonsList.reset()
        loading(argument, page)
        setPopupVisible(false)
      }
    }
  }

  useEffect(() => {
    if(argument == undefined) navigate(routes.learn)
    else {
      selectedLesson.setState(setSelectedLesson)
      lessonsList.setState(setLessonsList)
      loading(argument, page)
    }
  }, [page])
  

  return (
    <div id={"learn_lessons_page"}>
      <div className="flex justify-content-between">
        <h1>Education - Impara</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3 className="ml-2">Lezioni</h3>
      
      <MyTable 
        first={first}
        values={lessonsList.getItemsPerWindow(page)}
        totalRecords={lessonsList.getTotalItems()}
        onRowSelection={({ value }) => navigate(routes.learn_pages + "/" + value.getId())}
        itemsPerPage={lessonsList.getPageSize()}
        columns={[
          {field: "position", header: "Posizione"},
          {field: "name", header: "Nome"},
          {body: (...props) => <EditCell {...props} editFunction={editLesson} removeLesson={removeLesson}/>},
        ]}
      />
     
      <Popup
        title="Crea lezione"
        visible={popupVisible}
        setVisible={setPopupVisible}
        footer={<Footer saveFunction={saveLesson}/>}
        closeFunction={() => selectedLesson.reset()}
      >
        <label>Nome</label>
        <InputText
        value={selectedLesson.getName()}
        onChange={(e) => selectedLesson.setName(e.target.value)} placeholder={"Nome"}
        className="w-full mt-2"/><br/><br/>
        <label>Posizione</label>
        <InputText
        keyfilter="int"
        value={selectedLesson.getPosition()}
        onChange={(e) => selectedLesson.setPosition(toInteger(e.target.value))}
        placeholder={"Posizione"}
        className="w-full mt-2"/>
      </Popup>
    </div>
  )
}

function EditCell(props) {
  let lesson = props[0]
  let editFunction = props.editFunction
  let removeLesson = props.removeLesson
  const [loading, setLoading] = useState(false)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await removeLesson(lesson)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Button
      icon="pi pi-file-edit"
      text
      onClick={() => editFunction(lesson)}/>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}
      loading={loading}/>
    </>
  )
}

function Footer(props) {
  let saveFunction = props.saveFunction
  const [loading, setLoading] = useState(false)

  function remove() {

  }

  function save(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await saveFunction()
        setLoading(false)
      }
    })
  }

  return (
    <div>
      <Button label={"Salva"} onClick={save} loading={loading} />
    </div>
  )
}

export default LearnLessons