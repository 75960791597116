import React, { useState, useEffect, useContext } from 'react'
import QuestionController from '../../../../controllers/education/school_challenge/question_controller'
import QuestionsListController from '../../../../controllers/education/school_challenge/questions_list_controller'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router'
import { Context } from "../../../../App"
import routes from '../../../../router/routes'
import { Button } from 'primereact/button'
import MyTable from '../../../../components/my_table/my_table'
import { position } from 'dom-helpers'
import EditCell from '../../../../components/edit_cell'
import Popup from '../../../../components/popup'
import PopupFooter from '../../../../components/popup_footer'
import { InputText } from 'primereact/inputtext'
import ImagePicker from '../../../../components/image_picker'
import MultipleChoice from '../../../../components/multiuple_choice'

function QuestionPage() {
  const [selectedQuestion, setSelectedQuestion] = useState(new QuestionController())
  const [questionsList, setQuestionsList] = useState(new QuestionsListController())
  const [popupVisible, setPopupVisible] = useState(false)

  let { state } = useLocation()
  let showMessage = useContext(Context)
  let navigate = useNavigate()
  let params = useParams()

  let quiz = params.quiz
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / questionsList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    if(selectedQuestion.getText() == "") message.push("Titolo")
    // if(selectedQuestion.getImage() == "") message.push("Immagine")
    for(let answer of selectedQuestion.getAnswers()) {
      if(answer == "") {
        message.push("Risposta vuota")
        break
      }
    }
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading() {
    let result = await questionsList.load(quiz, page)
    if(result.error) showMessage(true, result.message)
  }

  async function saveQuestion() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedQuestion.publish(quiz)
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        selectedQuestion.reset()
        questionsList.reset()
        loading()
        setPopupVisible(false)
      }
    }
  }

  function editFunction(quiz) {
    selectedQuestion.buildFromExport(quiz.export())
    setPopupVisible(true)
  }

  async function deleteQuestion(quiz) {
    let result = await quiz.delete()
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Operazione completata")
      questionsList.reset()
      loading()
    }
  }

  useEffect(() => {
    if(quiz == undefined) navigate(routes.school_challenge)
    else {
      selectedQuestion.setState(setSelectedQuestion)
      questionsList.setState(setQuestionsList)

      loading()
    }
  }, [page])


  return (
    <div id="school_challenge_question_page">
      <div className="flex justify-content-between">
        <h1>School Challenge - Domande</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3>Domande</h3>

      <MyTable 
      first={first}
      values={questionsList.getItemsPerWindow(page)}
      totalRecords={questionsList.getTotalItems()}
      onRowSelection={({ value }) => editFunction(value)}
      itemsPerPage={questionsList.getPageSize()}
      arrowIconVisible={false}
      columns={[
        {field: "position", header: "Posizione"},
        {field: "text", header: "Domanda"},
        {body: (question) => <EditCell item={question} editFunction={editFunction} removeFunction={deleteQuestion}/>}
      ]}
      />

      <Popup
      title="Crea una domanda"
      visible={popupVisible}
      setVisible={setPopupVisible}
      closeFunction={() => selectedQuestion.reset()}
      footer={<PopupFooter saveFunction={saveQuestion}/>}
      >
        <label>Domanda</label><br/>
        <InputText className={"w-full mt-2"} placeholder={"domanda"} value={selectedQuestion.getText()} onChange={(e) => selectedQuestion.setText(e.target.value)}/><br/><br/>
        <label>Immagine</label>
        <div className="mt-2">
          <ImagePicker image_link={selectedQuestion.getImage()} setImageLink={(image) => selectedQuestion.setImage(image)} />
        </div>
      <label>Risposte</label><br/>
      <br/>
      <MultipleChoice
        answers={selectedQuestion.getAnswers()}
        addCorrectAnswer={(a) => selectedQuestion.addCorrectAnswer(a)}
        addAnswer={(a) => selectedQuestion.addAnswer(a)}
        correctAnswers={selectedQuestion.getCorrectAnswers()}
        editAnswerContent={(value, index) => selectedQuestion.editAnswerContent(value, index)}
        removeAnswer={(a) => selectedQuestion.removeAnswer(a)}
        removeCorrectAnswer={(a) => selectedQuestion.removeCorrectAnswer(a)}/>
      </Popup>
    </div>
  )
}

export default QuestionPage