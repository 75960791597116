import Database from "../../database";

class QuizDB {
  constructor() {
    this.db = new Database()
  }

  typeCheck(data) {
    let keys = Object.values(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('name')) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('position')) {
      if(typeof data['position'] != "number") {
        console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
        return false
      }
    }
    if(keys.includes('partner_link')) {
      if(typeof data['partner_link'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "partner_link is not a partner_link. Aborted")
        return false
      }
    }

    return true
  }

  async getAllQuiz(argument_slug, page_number, page_size) {
    let result = await this.db.get({
      path: `/gamification/school_challenge/argument/${argument_slug}/quiz?page_number=${page_number}&page_size=${page_size}`,
    })
    return result
  }

  async createQuiz(data, argument_slug) {
    if(typeof argument_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "argument_slug is not a string. Aborted")
      return false
    }

    let isCorrect = this.typeCheck(data)
    let result = {error: true}

    if(isCorrect) {
      result = await this.db.post({
        path: `/gamification/school_challenge/argument/${argument_slug}/quiz`,
        data: {
          name: data['name'],
          order: data['position'],
          partnerLink: data['partner_link']
        }
      })
    }
    return result
  }

  async updateQuiz(data) {
    let isCorrect = this.typeCheck(data)
    let result = {error: true}

    if(isCorrect) {
      result = await this.db.put({
        path: `/gamification/school_challenge/quiz/${data['id']}`,
        data: {
          name: data['name'],
          order: data['position'],
          partnerLink: data['partner_link']
        }
      })
    }

    return result
  }

  async deleteQuiz(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "slug is not a string. Aborted")
      return false
    }

    let result = await this.db.delete({
      path: `/gamification/school_challenge/quiz/${slug}`
    })
    return result
  }
}

export default QuizDB