import OrderedList from "../../../base_components/ordered_list";
import LessonsDB from "../../database/education/learn/lesson";
import LessonController from "./lesson_controller";

class LessonsListController extends OrderedList {
  constructor() {
    super()
    this.position_field_name = "position"
    this.page_size = 10
    this.total_items = 0
    this.page_index_relation = {}
  }

  getPageSize() { return this.page_size }
  getTotalItems() { return this.total_items }

  getItemsPerWindow(page) {
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return
    }
    if(Object.keys(this.page_index_relation).includes(page.toString())) {
      let start = this.page_index_relation[page]['start']
      let end = this.page_index_relation[page]['end']
      let window = this.list.slice(start, end)
      return window
    } else return []
  }

  async load(argument_slug, page) {
    let result = {error: false}

    if(!Object.keys(this.page_index_relation).includes(page.toString())) {
      let db = new LessonsDB()
      result = await db.getAllLessons(argument_slug, page, this.page_size)
      if(result.error) result['message'] = "Qaulcosa è andato storto..."
      else {
        let items = result.response.items
        this.total_items = result.response.total_items
        this.page_index_relation[page] = {start: this.list.length, end: this.list.length + items.length}
        for(let data of items) {
          this.addLesson({
            id: data['slug'],
            name: data['name'],
            position: data['order']
          }, false)
        }
      }

      this.updateInfo()
    }
    return result
  }

  addLesson(lesson, auto_update = true) {
    if(Array.isArray(lesson)) {
      console.warn(`[ ${this.constructor.name} ]`, "lesson is a list. Aborted")
      return
    } else if(lesson instanceof LessonController) {
      this.list.push(lesson)
    } else if(typeof lesson == "object") {
      // creating lesson instance
      let new_lesson = new LessonController()
      new_lesson.setOverrideState((() => this.updateInfo()).bind(this))
      new_lesson.buildFromExport(lesson)   // all the correctness aspects are inside the function
      this.list.push(new_lesson)
    }

    if(auto_update) this.updateInfo()
  }

  deleteLesson(lesson) {
    if(typeof lesson == "string") {
      // lesson is the lesson id
      let lesson_id = lesson
      lesson = this.list.find(item => item.getId() == lesson_id)
      this.removeItem(lesson)
    } else if(lesson instanceof LessonController) {
      // lesson is an instance of LessonController
      this.removeItem(lesson)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "lesson is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not in a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof LessonController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item of the list is not in a LessonController instance. Aborted")
          return
        }
      }
    }

    // loading data
    Object.assign(this, data)
    this.updateInfo()
  }
}

export default LessonsListController