import React from 'react'
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import "./style.css"
        
function Popup(props) {
  let children = props.children
  let title = props?.title
  let visible = props?.visible
  let setVisible = props?.setVisible
  let Footer = props?.footer
  let closeFunction = props?.closeFunction

  function onHide() {
    closeFunction()
    setVisible(false)
  }

  return (
    <div className="my_popup">
      <Dialog
      className="my_dialog"
      footer={Footer}
      closeOnEscape={true}
      draggable={false}
      header={title}
      visible={true}
      breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      position="bottom"
      onHide={onHide}
      visible={visible}
      maximizable={true}
      dismissableMask={true}>
        {children}
      </Dialog>
    </div>
  )
}

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  footer: PropTypes.node,
  closeFunction: PropTypes.func.isRequired
}


export default Popup