import MyComponent from "../base_components/my_component";
import UserDB from "./database/user";

class UserController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.name = ""
    this.surname = ""
    this.birthday = ""
    this.email = ""
    this.phone_number = ""
    this.parents = []
    this.roles = []
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    if(auto_update) this.updateInfo()
  }

  setSurname(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "surname is not a string. Aborted")
      return
    }

    this.surname = value
    if(auto_update) this.updateInfo()
  }

  setBirthday(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "birthday is not a string. Aborted")
      return
    }

    this.birthday = value
    if(auto_update) this.updateInfo()
  }

  setEmail(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "email is not a string. Aborted")
      return
    }

    this.email = value
    if(auto_update) this.updateInfo()
  }

  setPhoneNumber(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "phone_number is not a string. Aborted")
      return
    }

    this.phone_number = value
    if(auto_update) this.updateInfo()
  }

  setParents(value, auto_update = true) {
    // type check
    if(!(Array.isArray(value))) {
      console.warn(`[ ${this.constructor.name} ]`, "parents is not a list. Aborted")
      return
    }

    this.parents = value
    if(auto_update) this.updateInfo()
  }

  setRoles(value, auto_update = true) {
    // type check
    if(!(Array.isArray(value))) {
      console.warn(`[ ${this.constructor.name} ]`, "roles is not a list. Aborted")
      return
    }

    this.roles = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getName() { return this.name }
  getSurname() { return this.surname }
  getBirthday() { return this.birthday }
  getEmail() { return this.email }
  getPhoneNumber() { return this.phone_number}
  getParents() { return this.parents }
  getRoles() { return this.roles }

  // API
  async login(password) {
    let db = new UserDB()
    let result = await db.login(this.email, password)
    if(result.error) {

      // error
      let message = ""
      if(result.error.data.wrong_credentials) message = "Credenziali errate"
      if(result.error.data.email_not_verified) message = "Non hai verificato la mail"
      if(result.error.data.insufficient_permissions) message = "Non puoi accedere a quest'area"

      return {
        error: true,
        message: message
      }
    } else {
      // correct
      return {
        error: false
      }
    }
  }

  async load() {
    let db = new UserDB()
    let result = await db.getData()
    if(!result.error) {
      result = result.response
      let auto_update = false
      this.setName(result['name'], auto_update)
      this.setSurname(result['surname'], auto_update)
      this.setEmail(result['email_address'], auto_update)
      this.setBirthday(result['birth_day'], auto_update)
      this.setPhoneNumber(result['telephone_number'], auto_update)
      this.setRoles(result['role'], auto_update)
      this.updateInfo()
      return {error: false}
    } else {
      return {
        error: true,
        message: "Utente non loggato"
      }
    }
  }

  logout() {
    window.sessionStorage.removeItem('access_token')
    window.sessionStorage.removeItem('refresh_token')
  }

  canI(action) {
    switch (action) {
      case "news":
        // return this.roles = ""
        return true
      case "access":
        return this.roles != "app-user"
      default:
        return true
    }
  }

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data["id"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("name") && typeof data["name"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }
    if(keys.includes("surname") && typeof data["surname"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "surname is not a string. Aborted")
      return
    }
    if(keys.includes("birthday") && typeof data["birthday"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "birthday is not a string. Aborted")
      return
    }
    if(keys.includes("email") && typeof data["email"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "email is not a string. Aborted")
      return
    }
    if(keys.includes("phone_number") && typeof data["phone_number"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "phone_number is not a string. Aborted")
      return
    }
    if(keys.includes("parents") && !(Array.isArray(data['parents']))) {
      console.warn(`[ ${this.constructor.name} ]`, "parents is not a string. Aborted")
      return
    }
    if(keys.includes("roles") && !(Array.isArray(data['roles']))) {
      console.warn(`[ ${this.constructor.name} ]`, "roles is not a string. Aborted")
      return
    }

    // loading data
    Object.assign(this, data)
    this.updateInfo()
  }
}

export default UserController 