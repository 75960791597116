import MyComponent from "../../../base_components/my_component";
import ArgumentDB from "../../database/education/quiz/argument";
import QuizListController from "./quiz_list_controller";
import SubjectController from "./subject_controller";

class TopicController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.name = ""
    this.position = 0
    this.subject = new SubjectController()
    this.subject.setOverrideState((() => this.updateInfo()).bind(this))
    this.quiz = new QuizListController()
    this.quiz.setOverrideState((() => this.updateInfo()).bind(this))
  }

  // setter
  setId(value, auto_update = true) {
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value 
    if(auto_update) this.updateInfo()
  }

  setName(value, auto_update = true) {
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value 
    if(auto_update) this.updateInfo()
  }

  setPosition(value, auto_update = true) {
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    this.position = value 
    if(auto_update) this.updateInfo()
  }

  setSubject(value, auto_update = true) {
    if(!(value instanceof SubjectController)) {
      console.warn(`[ ${this.constructor.name} ]`, "subject is not a SubjectController instance. Aborted")
      return
    }

    this.subject = value 
    if(auto_update) this.updateInfo()
  }

  setQuiz(value, auto_update = true) {
    if(!(value instanceof QuizListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "quiz is not a QuizListController instance. Aborted")
      return
    }

    this.quiz = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getName() { return this.name }
  getSubject() { return this.subject }
  getQuiz() {return this.quiz }
  getPosition() {return this.position }

  // API
  async create(subject_slug) {
    let db = new ArgumentDB()
    let result = db.createArgument({
      name: this.getName(),
      position: this.getPosition()
    }, subject_slug)
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }
  
  async update() {
    let db = new ArgumentDB()
    let result = db.updateArgument({
      id: this.getId(),
      name: this.getName(),
      position: this.getPosition()
    })
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new ArgumentDB()
    let result = db.deleteArgument(this.getId())
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish(subject_slug) {
    if(this.getId() == "") return await this.create(subject_slug)
    else return await this.update()
  }

  load() {}

  loadQuiz() {}

  loadSubject() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data['id'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("name") && typeof data['name'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    // loading data
    if(keys.includes("subject")) {
      this.subject.buildFromExport(data['subject'])
      delete data['subject']
    }
    if(keys.includes('quiz')) {
      this.quiz.buildFromExport(data['quiz'])
      delete data['quiz']
    }
    
    Object.assign(this, data)
    this.updateInfo()
  }

}

export default TopicController