import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Button } from 'primereact/button'
import QuizController from '../../../../controllers/education/quiz/quiz_controller'
import QuizListController from '../../../../controllers/education/quiz/quiz_list_controller'
import { Context } from "../../../../App"
import routes from '../../../../router/routes'
import MyTable from '../../../../components/my_table/my_table'
import EditCell from '../../../../components/edit_cell'
import Popup from '../../../../components/popup'
import PopupFooter from '../../../../components/popup_footer'
import { InputText } from 'primereact/inputtext'
import { toInteger } from 'lodash'

function QuizPage() {
  const [selectedQuiz, setSelectedQuiz] = useState(new QuizController())
  const [quizList, setQuizList] = useState(new QuizListController())
  const [popupVisible, setPopupVisible] = useState(false)

  let params = useParams()
  let navigate = useNavigate()
  let { state } = useLocation()
  let showMessage = useContext(Context)

  let argument = params.argument
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / quizList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    if(selectedQuiz.getName() == "") message.push("Nome")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading() {
    let result = await quizList.load(argument, page)
    if(result.error) showMessage(true, "Qualcosa è andato storto...")
  }

  async function saveQuiz() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedQuiz.publish(argument)
      if(result.error) showMessage(true, "Qualcosa è andato storto...")
      else {
        showMessage(false, "Operazione completata")
        selectedQuiz.reset()
        quizList.reset()
        loading()
        setPopupVisible(false)
      }
    }
  }

  async function deleteQuiz(quiz) {
    let result = await quiz.delete()
    if(result.error) showMessage(true, "Qualcosa è andato storto...")
    else {
      showMessage(false, "Operazione completata")
      quizList.reset()
      loading()
    }
  }

  async function editFunction(quiz) {
    selectedQuiz.buildFromExport(quiz.export())
    setPopupVisible(true)
  }

  useEffect(() => {
    if(argument == undefined) navigate(routes.quiz)
    else {
      selectedQuiz.setState(setSelectedQuiz)
      quizList.setState(setQuizList)
      loading()
    }
  }, [page])
  

  return (
    <div>
      <div className="flex justify-content-between">
        <h1>Education - Quiz</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3>Quiz</h3>

      <MyTable
      first={first}
      values={quizList.getItemsPerPage(page)}
      totalRecords={quizList.getTotalItems()}
      onRowSelection={({ value }) => navigate(routes.quiz_question + "/" + value.getId())}
      itemsPerPage={quizList.getPageSize()}
      columns={[
        {field: "position", header: "Posizione"},
        {field: "name", header: "Nome"},
        {body: (quiz) => <EditCell item={quiz} editFunction={editFunction} removeFunction={deleteQuiz} />}
      ]}
      />

      <Popup
      title={"Crea quiz"}
      visible={popupVisible}
      setVisible={setPopupVisible}
      closeFunction={() => selectedQuiz.reset()}
      footer={<PopupFooter saveFunction={saveQuiz} />}
      >
        <label>Nome</label>
        <InputText placeholder={"Nome"} className="w-full mt-2" value={selectedQuiz.getName()} onChange={(e) => selectedQuiz.setName(e.target.value)} /><br/><br/>
        <label>Posizione</label>
        <InputText keyfilter={"int"} placeholder={"Posizione"} className="w-full mt-2" value={selectedQuiz.getPosition()} onChange={(e) => selectedQuiz.setPosition(toInteger(e.target.value))} />
      </Popup>
    </div>
  )
}

export default QuizPage