import OrderedList from "../../../base_components/ordered_list";
import TopicDB from "../../database/education/learn/topic";
import TopicController from "./topic_controller";

class TopicsListController extends OrderedList {
  constructor() {
    super()
    this.position_field_name = "position"
    this.page_size = 10
    this.total_items = 0
    this.page_index_relation = {}
  }

  getPageSize() { return this.page_size }
  getTotalItems() { return this.total_items }
  
  getTopicsPerWindow(page) {
    if(Object.keys(this.page_index_relation).includes(page.toString())) {
      let start = this.page_index_relation[page]['start']
      let end = this.page_index_relation[page]['end']
      let window = this.list.slice(start, end)
      console.log(window)
      return window
    } else return []
  }

  async load(macro_argument_slug, page) {
    if(!Object.keys(this.page_index_relation).includes(page.toString())) {
      let db = new TopicDB()
      let result = await db.getAllTopics(macro_argument_slug, page, this.page_size)
      if(result.error) result['message'] = "Qualcosa è andato storto"
      let items = result.response.items
      this.page_index_relation[page] = {start: this.list.length, end: this.list.length + items.length}
      this.total_items = result.response.total_items
      for(let data of items) {
        this.addTopic({
          id: data['slug'],
          image: data['image'],
          title: data['name'],
          description: data['description'],
          position: data['order'],
        }, false)
      }
      this.updateInfo()
      return result
    }

  } 

  addTopic(topic, auto_update = false) {
    if(Array.isArray(topic)) {
      console.warn(`[ ${this.constructor.name} ]`, "topic is a list. Aborted")
      return
    } else if(topic instanceof TopicController) {
      this.list.push(topic)
    } else if(typeof topic == "object") {
      // creating topic instance
      let new_topic = new TopicController()
      new_topic.setOverrideState((() => this.updateInfo()).bind(this))
      new_topic.buildFromExport(topic)   // all the correctness aspects are inside the function
      this.list.push(new_topic)
    }

    if(auto_update) this.updateInfo()
  }

  deleteTopic(topic) {
    if(typeof topic == "string") {
      // topic is the topic id
      let topic_id = topic
      topic = this.list.find(item => item.getId() == topic_id)
      this.removeItem(topic)
    } else if(topic instanceof TopicController) {
      // topic is an instance of TopicController
      this.removeItem(topic)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "topic is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = this.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof TopicController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item in the list is not a TopicController instance. Aborted")
          return
        }
      }
    }

    // loading data
    Object.assign(this, data)
    this.updateInfo()
  }

}

export default TopicsListController