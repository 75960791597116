import OrderedList from "../../../base_components/ordered_list";
import MacroArgumentDB from "../../database/education/learn/macro_argument";
import MacroTopicController from "./macro_topic_controller";
import TopicController from "./topic_controller";

class MacroTopicsListController extends OrderedList {
  constructor() {
    super()
    this.position_field_name = "position"
    this.page_size = 10
    this.total_items = 0
    this.page_index_relation = {}
  }
  
  getPageSize() { return this.page_size }
  getTotalItems() { return this.total_items }

  getItemsPerWindow(page) {
    if(Object.keys(this.page_index_relation).includes(page.toString())) {
      let start = this.page_index_relation[page]['start']
      let end = this.page_index_relation[page]['end']
      let window = this.list.slice(start, end)
      return window
    } else return []
  }

  // API 
  async load(page, subject_slug) {
    let result = {error: false}
    if(!Object.keys(this.page_index_relation).includes(page.toString())) {
      let db = new MacroArgumentDB()
      let result = await db.getAllMAcroArguments(page, this.page_size, subject_slug)
      if(result.error) result['message'] = "Qualcosa è andato storto..."
      else {
        let items = result.response.items
        this.total_items = result.response.total_items
        this.page_index_relation[page] = {start: this.list.length, end: this.list.length + items.length}

        for(let data of items) {
          this.addMacroTopic({
            id: data['slug'],
            name: data['name'],
            position: data['order']
          }, false)
        }
        
        this.updateInfo()
      }
    }
    return result
  }

  addMacroTopic(macro_topic, auto_update = true) {
    if(Array.isArray(macro_topic)) {
      console.warn(`[ ${this.constructor.name} ]`, "macro_topic is a list. Aborted")
      return
    } else if(macro_topic instanceof MacroTopicController) {
      // this.addItem(macro_topic)
      this.list.push(macro_topic)
    } else if(typeof macro_topic == "object") {
      // creating macro_topic instance
      let new_macro_topic = new MacroTopicController()
      new_macro_topic.setOverrideState((() => this.updateInfo()).bind(this))
      new_macro_topic.buildFromExport(macro_topic)   // all the correctness aspects are inside the function
      // this.addItem(new_macro_topic)
      this.list.push(new_macro_topic)
    }

    if(auto_update) this.updateInfo()
  }

  deleteMacroTopic(macro_topic) {
    if(typeof macro_topic == "string") {
      // macro_topic is the macro_topic id
      let macro_topic_id = macro_topic
      macro_topic = this.list.find(item => item.getId() == macro_topic_id)
      this.removeItem(macro_topic)
    } else if(macro_topic instanceof MacroTopicController) {
      // macro_topic is an instance of MacroTopicController
      this.removeItem(macro_topic)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "macro_topic is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not in a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof MacroTopicController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item of the list is not in a MacroTopicController instance. Aborted")
          return
        }
      }
    }

    Object.assign(this, data)
    this.updateInfo()
  }

}

export default MacroTopicsListController