import Database from "../../database";

class QuestionDB {
  constructor() {
    this.db = new Database()
  }

  typeCheck(data) {
    let keys = Object.keys(data)

    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('text')) {
      if(typeof data['text'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "text is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('image')) {
      if(typeof data['image'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('answers')) {
      if(!Array.isArray(data['answers'])) {
        console.warn(`[ ${this.constructor.name} ]`, "answers is not an array. Aborted")
        return false
      }
    }
    if(keys.includes('position')) {
      if(typeof data['position'] != "number") {
        console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
        return false
      }
    }
    if(keys.includes('correctAnswers')) {
      if(!Array.isArray(data['correctAnswers'])) {
        console.warn(`[ ${this.constructor.name} ]`, "correctAnswers is not an array. Aborted")
        return false
      }
    }

    return true
  }

  async getAllQuestions(quiz_slug, page_number, page_size) {
    if(typeof quiz_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "quiz_slug is not a string. Aborted")
      return false
    }
    if(typeof page_number != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page_number is not a number. Aborted")
      return false
    }
    if(typeof page_size != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page_size is not a number. Aborted")
      return false
    }

    let result = await this.db.get({
      path: `/gamification/school_challenge/quiz/${quiz_slug}/questions?page_number=${page_number}&page_size=${page_size}&admin=1`,
    })

    return result
  }

  async createQuestion(data, quiz_slug) {
    if(typeof quiz_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "quiz_slug is not a string. Aborted")
      return false
    }

    let isCorrect = this.typeCheck(data)
    let result = { error: true }

    if(isCorrect) {
      let imageFile = await fetch(data['image'])
      .then((data) => data.blob())
      .then(blob => {
        const file = new File([blob], 'image.png', { type: 'image/png' });
        return file
      })

      let formData = new FormData()

      if(data['image' != ""]) formData.append("image", imageFile)
      formData.append("question", data['text'])
      formData.append("order", data['position'])
      for(let i = 1; i <= data['answers'].length; i++) formData.append(`answer${i}`, data['answers'][i - 1])
      for(let index of data['correctAnswers']) formData.append(`isAnsw${index}Correct`, true)
      
      result = await this.db.post({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/gamification/school_challenge/quiz/${quiz_slug}/questions`,
        data: formData
      })
    }
    return result
  }

  async updateQuestion(data) {
    let isCorrect = this.typeCheck(data)
    let result = { error: true }
    if(isCorrect) {
      let formData = new FormData()

      if(data['image'].substring(0,4) == "blob") {
        let imageFile = await fetch(data['image'])
        .then((data) => data.blob())
        .then(blob => {
          const file = new File([blob], 'image.png', { type: 'image/png' });
          return file
        })
        formData.append("image", imageFile)
      }

      formData.append("question", data['text'])
      formData.append("order", data['position'])
      if(data["answers"][0] != undefined) formData.append("answer1", data["answers"][0])
      else formData.append("answer1", null)
      if(data["answers"][1] != undefined) formData.append("answer2", data["answers"][1])
      if(data["answers"][2] != undefined) formData.append("answer3", data["answers"][2])
      if(data["answers"][3] != undefined) formData.append("answer4", data["answers"][3])
      formData.append("isAnsw1Correct", data["correctAnswers"].includes(1) ? true : "")
      formData.append("isAnsw2Correct", data["correctAnswers"].includes(2) ? true : "")
      formData.append("isAnsw3Correct", data["correctAnswers"].includes(3) ? true : "")
      formData.append("isAnsw4Correct", data["correctAnswers"].includes(4) ? true : "")

      result = await this.db.put({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/gamification/school_challenge/question/${data['id']}`,
        data: formData
      })

      let list_lenght = data["answers"].length
      result = this.db.delete({
        path: `/gamification/school_challenge/question/${data['id']}/answer`,
        data: {
          answer1: list_lenght < 1,
          answer2: list_lenght < 2,
          answer3: list_lenght < 3,
          answer4: list_lenght < 4
        }
      })
    }

    return result
  }

  async deleteQuestion(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "slug is not a string. Aborted")
      return false
    }

    let result = await this.db.delete({
      path: `/gamification/school_challenge/question/${slug}`
    })

    return result
  }
}

export default QuestionDB