import axios from "axios"

const dev_mode = false

class Database {
  constructor() {
    this.base_url = dev_mode ? "https://sil-dev.herokuapp.com" : "https://api.waresoft.tech"
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
      let [key,value] = el.split('=');
      cookie[key.trim()] = value;
    })
    return cookie[cookieName];
  }

  async request({method, path, data = {}, successFunction = () => {}, errorFunction = () => {}, headers = {}, after_refresh = false}) {
    // type check
    if(typeof method != "string") {
      console.warn("[ DATABASE TYPE ERROR ] method is not a string. Aborted")
      return
    }
    if(typeof path != "string") {
      console.warn("[ DATABASE TYPE ERROR ] path is not a string. Aborted")
      return
    }
    if(typeof successFunction != "function") {
      console.warn("[ DATABASE TYPE ERROR ] successFunction is not a function. Aborted")
      return
    }
    if(typeof errorFunction != "function") {
      console.warn("[ DATABASE TYPE ERROR ] errorFunction is not a function. Aborted")
      return
    }

    // return object
    let result = {
      error: false,
      response: {}
    }

    // make request
    await axios.request({
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": dev_mode ? undefined : this.getCookie('csrf_access_token'),
        "Authorization": dev_mode ? "Bearer " + sessionStorage.getItem('access_token') : undefined,
        ...headers
      },
      url: this.base_url + path,
      method: method,
      data: data,
      withCredentials: true
    }).then(async (response) => {
      // success
      await successFunction(response.data)
      result.response = response.data
    }).catch(async (error) => {
      // error
      if(error.response.status == 401 && (!dev_mode && document.cookie != "")) {
        // refresh token
        await this.post({
          path: "/auth/refresh_token",
          // headers: { "Authorization": dev_mode ? "Bearer " + sessionStorage.getItem('refresh_token') : this.getCookie('csrf_refresh_token')},
          successFunction: async (response) => {
            window.sessionStorage.setItem("access_token", response.access_token)
            let request_data = await this.request({method : method, path : path, data : data, successFunction : successFunction, errorFunction : errorFunction, headers : headers, after_refresh : true})
            result.response = request_data.response
          },
        })
      } else result.error = error.response
    })

    return result
  }

  async get({path, data = undefined, headers = {}, successFunction = () => {}, errorFunction = () => {}}) {
    return this.request({
      method: "GET",
      path: path,
      data: data,
      headers: headers,
      successFunction: successFunction,
      errorFunction: errorFunction
    })
  }

  async post({path, data = {}, headers = {}, successFunction = () => {}, errorFunction = () => {}}) {
    return this.request({
      method: "POST",
      path: path,
      data: data,
      headers: headers,
      successFunction: successFunction,
      errorFunction: errorFunction
    })
  }

  async put({path, data = undefined, headers = {}, successFunction = () => {}, errorFunction = () => {}}) {
    return this.request({
      method: "PUT",
      path: path,
      data: data,
      headers: headers,
      successFunction: successFunction,
      errorFunction: errorFunction
    })
  }

  async delete({path, data = undefined, headers = {}, successFunction = () => {}, errorFunction = () => {}}) {
    return this.request({
      method: "DELETE",
      path: path,
      data: data,
      headers: headers,
      successFunction: successFunction,
      errorFunction: errorFunction
    })
  }
}

export { dev_mode }
export default Database