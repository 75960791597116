import React from 'react'
import { Checkbox } from 'primereact/checkbox';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

function MultipleChoice(props) {
  /**
   * It works with an array structure
   * where the answer i is in array[i - 1].
   */

  let answers = props.answers
  let addCorrectAnswer = props.addCorrectAnswer
  let addAnswer = props.addAnswer
  let correctAnswers = props.correctAnswers
  let editAnswerContent = props.editAnswerContent
  let removeAnswer = props.removeAnswer
  let removeCorrectAnswer = props.removeCorrectAnswer
  let maxAnswers = props.maxAnswers == undefined ? 4 : props.maxAnswers

  return (
    <div>
      {
        answers.length == 0 ?
        <i><p className="mt-0">Nessuna risposta inserita</p></i> : 
        answers.map((answer, index) => (
          <div className="flex flex-row align-items-center mb-2">
            <Checkbox
            className="mr-2"
            onChange={e => e.checked ? addCorrectAnswer(index + 1) : removeCorrectAnswer(index + 1)}
            checked={correctAnswers.includes(index + 1)} />
            <InputText
            placeholder="Risposta"
            value={answer}
            onChange={e => editAnswerContent(e.target.value, index)}/>
            <i
            className="ml-2 pi pi-trash"
            onClick={() => removeAnswer(index)}></i>
          </div>
        ))
      }
      {
        answers.length == maxAnswers ?
        "" : 
        <Button
        label="Aggiungi"
        icon={"pi pi-plus-circle"}
        link
        onClick={() => addAnswer()}/>
      }
    </div>
  )
}


MultipleChoice.propTypes = {
  answers : PropTypes.array.isRequired,
  addCorrectAnswer : PropTypes.func.isRequired,
  addAnswer : PropTypes.func.isRequired,
  correctAnswers : PropTypes.array.isRequired,
  editAnswerContent : PropTypes.func.isRequired,
  removeAnswer : PropTypes.func.isRequired,
  removeCorrectAnswer : PropTypes.func.isRequired,
  maxAnswers : PropTypes.number
}

export default MultipleChoice