import React, {useState, useEffect, useContext} from 'react'
import { Button } from "primereact/button"
import SubjectController from '../../../../controllers/education/quiz/subject_controller'
import SubjectsListController from '../../../../controllers/education/quiz/subjects_list_controller'
import { useLocation, useNavigate } from 'react-router'
import { Context } from "../../../../App"
import MyTable from '../../../../components/my_table/my_table'
import { Avatar } from 'primereact/avatar'
import { confirmPopup } from 'primereact/confirmpopup'
import Popup from '../../../../components/popup'
import ImagePicker from '../../../../components/image_picker'
import { InputText } from 'primereact/inputtext'
import routes from '../../../../router/routes'

function QuizEducation() {
  const [popupVisible, setPopupVisible] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState(new SubjectController())
  const [subjectsList, setSubjectsList] = useState(new SubjectsListController())

  let { state } = useLocation()
  let navigate = useNavigate()
  let showMessage = useContext(Context)

  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / subjectsList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    if(selectedSubject.getImage() == "") message.push("Immagine")
    if(selectedSubject.getName() == "") message.push("Nome")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading() {
    let result = await subjectsList.load(page)
    if(result.error) showMessage(true, result.message)
  }

  async function saveSubject() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedSubject.publish()
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        selectedSubject.reset()
        subjectsList.reset()
        loading()
        setPopupVisible(false)
      }
    }
  }

  async function removeSubject(subject) {
    let result = await subject.delete()
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Operazione completata")
      subjectsList.reset()
      loading()
    }
  }

  async function editFunction(subject) {
    selectedSubject.buildFromExport(subject.export())
    setPopupVisible(true)
  }

  useEffect(() => {
    selectedSubject.setState(setSelectedSubject)
    subjectsList.setState(setSubjectsList)
    loading()
  }, [page])
  

  return (
    <div className="quiz_subject">
      <div className="flex justify-content-between">
        <h1>Education - Quiz</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3>Materie</h3>

      <MyTable 
      first={first}
      values={subjectsList.getItemsPerWindow(page)}
      totalRecords={subjectsList.getTotalItems()}
      onRowSelection={({ value }) => navigate(routes.quiz_argument + "/" + value.getId())}
      itemsPerPage={subjectsList.getPageSize()}
      columns={[
        {field: "image", header: "Immagine", body: (subject) => <Avatar image={subject.getImage()} shape="circle" size="large"/> },
        {field: "name", header: "Nome"},
        {body: (...props) => <EditCell {...props} editFunction={editFunction} removeSubject={removeSubject} />}
      ]}
      />

      <Popup
      title="Crea Materia"
      visible={popupVisible}
      setVisible={setPopupVisible}
      footer={<PopupFooter saveFunction={saveSubject} />}
      closeFunction={() => selectedSubject.reset()}>
        <label>Immagine</label>
        <div className="mt-2">
          <ImagePicker image_link={selectedSubject.getImage()} setImageLink={(image) => selectedSubject.setImage(image)}/>
        </div>
        <label>Nome</label>
        <InputText placeholder="Nome" value={selectedSubject.getName()} onChange={(e) => selectedSubject.setName(e.target.value)} className="w-full mt-2" />
      </Popup>

    </div>
  )
}

function EditCell(props) {
  let subject = props[0]
  let editFunction = props.editFunction
  let removeSubject = props.removeSubject
  const [loading, setLoading] = useState(false)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await removeSubject(subject)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Button
      icon="pi pi-file-edit"
      text
      onClick={() => editFunction(subject)}/>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}
      loading={loading}/>
    </>
  )
}

function PopupFooter(props) {
  let saveFunction = props.saveFunction
  const [loading, setLoading] = useState(false)

  function save(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await saveFunction()
        setLoading(false)
      }
    })
  }
  return (
    <div>
      <Button label="Cancella"/>
      <Button label="Salva" loading={loading} onClick={save}/>
    </div>
  )
}

export default QuizEducation