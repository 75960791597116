import { useState } from "react";
import { createBrowserRouter, useNavigate, useLocation } from "react-router-dom";
import routes from "./routes";

let elemento = <Gino />

function Prova(props) {
  const [element, setElement] = useState("Non cliccato")
  let navigate = useNavigate()
  let location = useLocation()

  return (
    <div>
      {elemento}
      {elemento}
      <button onClick={() => setElement("Cliccato")}>Change</button>
      <button onClick={() => navigate(routes.home, { state: {location_info: location}})}>Indietro</button>
      
    </div>
  )
}

function Gino() {
  return (
    <div>
      <input />
    </div>
  )
}

let element = <Prova />

let route = {
  path: routes.prova,
  element: element,
}

export default route