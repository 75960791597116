import Database from "../../database";

class QuizDB {
  constructor() {
    this.db = new Database()
  }

  typeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('name')) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('position')) {
      if(typeof data['position'] != "number") {
        console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
        return false
      }
    }
    return true
  }

  async getAllQuiz(argument_slug, page_number, page_size) {
    let result = await this.db.get({
      path: `/gamification/quiz/argument/${argument_slug}/quiz?page_number=${page_number}&page_size=${page_size}`,
    })
    return result
  }

  async createQuiz(data, argument_slug) {
    if(typeof argument_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "argument_slug is not a string. Aborted")
      return false
    }

    let isCorrect = this.typeCheck(data)
    let result = {error: true}

    if(isCorrect) {
      result = await this.db.post({
        path: `/gamification/quiz/argument/${argument_slug}/quiz`,
        data: {
          name: data['name'],
          order: data['position']
        }
      })
    }
    return result
  }

  async updateQuiz(data) {
    let isCorrect = this.typeCheck(data)
    let result = {error: true}

    if(isCorrect) {
      result = await this.db.put({
        path: `/gamification/quiz/${data['id']}`,
        data: {
          name: data['name'],
          order: data['position']
        }
      })
    }
    return result
  }

  async deleteQuiz(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "slug is not a string. Aborted")
      return false
    }

    let result = await this.db.delete({
      path: `/gamification/quiz/${slug}`
    })
    return result
  }
}

export default QuizDB