import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'primereact/button'
import routes from '../../../../router/routes'
import { Divider } from 'primereact/divider'
import { Card } from 'primereact/card'
import { confirmPopup } from 'primereact/confirmpopup'
import "./style.css"
import { useLocation, useNavigate, useParams } from 'react-router'
import Popup from '../../../../components/popup'
import { InputText } from 'primereact/inputtext'
import MyTable from '../../../../components/my_table/my_table'
import MacroTopicsListController from '../../../../controllers/education/learn/macro_topics_list_controller'
import MacroTopicController from '../../../../controllers/education/learn/macro_topic_controller'
import { Context } from "../../../../App"
import { BreadCrumb } from 'primereact/breadcrumb';

function LearnMacroArgument() {
  const [selectedMacroArgument, setSelectedMacroArgument] = useState(new MacroTopicController())
  const [macroArgumentsList, setMacroArgumentsList] = useState(new MacroTopicsListController())
  const showMessage = useContext(Context)

  let navigate = useNavigate()
  let params = useParams()
  let { state } = useLocation()

  let subject = params?.subject
  let first = state?.first != undefined ? state['first'] : 0

  let page = Math.floor((first / macroArgumentsList.getPageSize()) + 1)
  const [popupVisible, setPopupVisible] = useState(false)

  function checkRequiredFields() {
    let message = []
    if(selectedMacroArgument.getName() == "") message.push("Nome")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading() {
    let result = await macroArgumentsList.load(page, subject)
    if(result.error) showMessage(true, result.message)
  }

  async function deleteMacroArgument(macroArgument) {
    let result = await macroArgument.delete()
    if(result.error) showMessage(true, result.message)
    else {
      macroArgumentsList.deleteMacroTopic(macroArgument.getId())
      macroArgumentsList.reset()
      showMessage(false, "Operazione completata")
      loading(page)
    }
  }

  function editMacroArgument(macroArgument) {
    selectedMacroArgument.buildFromExport(macroArgument.export())
    setPopupVisible(true)
  }

  async function createMacroArgument() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedMacroArgument.publish(subject)
      if(result.error) showMessage(true, result.message)
      else showMessage(false, "Operazione completata")
      macroArgumentsList.reset()
      loading(page)
      selectedMacroArgument.reset()
      setPopupVisible(false)
    }
  }

  function closePopup() {
    selectedMacroArgument.reset()
  }

  useEffect(() => {
    if(subject == undefined) {
      navigate(routes.learn)
    } else {
      selectedMacroArgument.setState(setSelectedMacroArgument)
      macroArgumentsList.setState(setMacroArgumentsList)
      loading()
    }
  }, [page])
  

  return (
    <div id="learn_macro_argument_page">
      <div className="flex justify-content-between">
        <h1>Education - Impara</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>
      
      <h3 className="ml-2">Macro argomenti</h3>
      
      <Divider />

      <MyTable 
      first={first}
      values={macroArgumentsList.getItemsPerWindow(page)}
      totalRecords={macroArgumentsList.getTotalItems()}
      onRowSelection={({value}) => navigate(routes.learn_argument + `/${value.getId()}`)}
      itemsPerPage={macroArgumentsList.getPageSize()}
      columns={[
        {field: "position", header: "Posizione"},
        {field: "name", header: "Nome"},
        {field: "macro_argument", body: (...props) => <EditCell {...props} deleteMacroArgument={deleteMacroArgument} editMacroArgument={editMacroArgument}/>}
      ]}
      />
      
      <Popup
      footer={<PopupFooter macroArgument={selectedMacroArgument} saveFunction={createMacroArgument}/>}
      visible={popupVisible}
      setVisible={setPopupVisible}
      closeFunction={closePopup}
      title="Aggiungi macro argomento">
        <label>Nome</label>
        <InputText placeholder="Nome" className="w-full mt-2" value={selectedMacroArgument.getName()} onChange={(e) => selectedMacroArgument.setName(e.target.value)}/><br/><br/>
        <label>Posizione</label>
        <InputText keyfilter={"int"} placeholder="Posizione" className="w-full mt-2" value={selectedMacroArgument.getPosition()} onChange={(e) => selectedMacroArgument.setPosition(e.target.value)}/>
      </Popup>
    </div>
  )
}


function EditCell(props) {
  let macroArgument = props['0']
  let deleteMacroArgument = props.deleteMacroArgument
  let editMacroArgument = props.editMacroArgument
  const [loading, setLoading] = useState(false)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro",
      accept: async () => {
        setLoading(true)
        await deleteMacroArgument(macroArgument)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Button
      icon="pi pi-file-edit"
      text
      onClick={() => editMacroArgument(macroArgument)}/>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}
      loading={loading}/>
    </>
  )
}

function PopupFooter(props) {
  let macroArgument = props.macroArgument
  let saveFunction = props.saveFunction
  const [loading, setLoading] = useState(false)

  function save(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await saveFunction()
        setLoading(false)
      }
    })
  }

  return (
    <div>
      <Button label={"Salva"} onClick={save} loading={loading}/>
    </div>
  )
}

export default LearnMacroArgument