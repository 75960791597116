import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { useLocation, useNavigate } from 'react-router';
import { Column } from 'primereact/column';
import { indexOf } from 'lodash';
import { createBrowserHistory } from "history";
import { Button } from 'primereact/button';

function MyTable(props) {
  let first = props.first
  let values = props.values
  let total_records = props.totalRecords
  let onRowSelection = props.onRowSelection
  let items_per_page = props.itemsPerPage
  let columns = props.columns
  let arrowIconVisible = props.arrowIconVisible == null ? true : props.arrowIconVisible
  let history = createBrowserHistory()
  let { state } = useLocation()

  let location = useLocation()
  let navigate = useNavigate()

  function changeTablePage(info) {
    let url = location.pathname
    console.log(state)
    navigate(url, {state: {first: info.first}})
    // history.push(url)
  }

  return (
    <>
      <DataTable
      first={0}
      paginator
      paginatorTemplate={{layout: ""}}
      totalRecords={total_records}
      tableStyle={{ minWidth: '50rem' }}
      value={values}
      onRowClick={() => {}}
      selectionMode="single"
      onSelectionChange={onRowSelection}
      rows={items_per_page}
      resizableColumns
      >
        {columns.map((column, i) => <Column key={i} {...column} />)}
        {
          !arrowIconVisible ? "" :
            <Column body={() => <i className="pi pi-arrow-circle-right" style={{ color: 'var(--primary-color)' }}></i>} />
        }
      </DataTable>
      <Paginator
      first={first}
      rows={items_per_page}
      totalRecords={total_records}
      onPageChange={changeTablePage}
      />
    </>
  )
}

MyTable.propTypes = {
  first: PropTypes.number.isRequired,
  values: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
}

export default MyTable