import MyComponent from "../../../base_components/my_component";
import TopicDB from "../../database/education/learn/topic";
import LessonsListController from "./lessons_list_controller";
import MacroTopicController from "./macro_topic_controller";

class TopicController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.image = ""
    this.title = ""
    this.position = 0
    this.description = ""
    this.lessons = new LessonsListController()
    this.macro_topic = new MacroTopicController()
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setImage(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }

    this.image = value
    if(auto_update) this.updateInfo()

  }

  setTitle(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "title is not a string. Aborted")
      return
    }

    this.title = value
    if(auto_update) this.updateInfo()
      
  }

  setPosition(value, auto_update = true) {
    // type check
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    this.position = value
    if(auto_update) this.updateInfo()

  }

  setDescription(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
      return
    }

    this.description = value
    if(auto_update) this.updateInfo()
  }

  setLessons(value, auto_update = true) {
    // type check
    if(!(value instanceof LessonsListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "lessons is not a LessonsListController instance. Aborted")
      return
    }

    this.lessons = value
    if(auto_update) this.updateInfo()
  }

  setMacroTopic(value, auto_update = true) {
    // type check
    if(!(value instanceof MacroTopicController)) {
      console.warn(`[ ${this.constructor.name} ]`, "macro_topic is not a MacroTopic instance. Aborted")
      return
    }

    this.macro_topic = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getImage() { return this.image }
  getTitle() { return this.title }
  getPosition() { return this.position }
  getDescription() { return this.description }
  getLessons() { return this.lessons }
  getMacroTopic() { return this.macro_topic}

  // API
  async create(macro_argument) {
    let db = new TopicDB()
    let result = await db.createTopic({
      image: this.getImage(),
      title: this.getTitle(),
      position: this.getPosition(),
      description: this.getDescription()
    }, macro_argument)
    if(result.error) result['message'] = "Qualcosa è andato storto"
    return result
  }

  async update() {
    let db = new TopicDB()
    let result = await db.updateTopic({
      id: this.getId(),
      image: this.getImage(),
      title: this.getTitle(),
      position: this.getPosition(),
      description: this.getDescription()
    })
    if(result.error) result['message'] = "Qualcosa è andato storto"
    return result
  }

  async delete() {
    let db = new TopicDB()
    let result = await db.deleteTopic(this.getId())
    if(result.error) result['message'] = "Qualcosa è andato storto"
    return result
  }

  async publish(macro_argument) {
    if(this.getId() == "") return await this.create(macro_argument)
    else return await this.update()
  }

  load() {}

  loadLessons() {}

  loadMacroTopic() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('id') && typeof data['id'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes('image') && typeof data['image'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }
    if(keys.includes('title') && typeof data['title'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "title is not a string. Aborted")
      return
    }
    if(keys.includes('position') && typeof data['position'] != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }
    if(keys.includes('description') && typeof data['description'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
      return
    }

    // loading data
    if(keys.includes('macro_topic')) {
      this.macro_topic.buildFromExport(data['macro_topic'])
      delete data['macro_topic']
    }
    if(keys.includes('lessons')) {
      this.lessons.buildFromExport(data['lessons'])
      delete data['lessons']
    }

    Object.assign(this, data)
    this.updateInfo()
  }
}

export default TopicController