import MyComponent from "../../base_components/my_component";
import StuffListController from "./stuff_list_controller";
import UniversityDB from "../database/orientation/university"

class TopicController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.name = ""
    this.partner_name = ""
    this.partner_link = ""
    this.description = ""
    this.type = ""
    this.stuff = new StuffListController()
    this.stuff.setOverrideState((() => this.updateInfo()))
    this.edited = false
    this.university = true
  }

  updateInfo() {
    let newInstance = super.updateInfo()
    if(newInstance != null) newInstance.stuff.setOverrideState((() => newInstance.updateInfo()))
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    this.edited = true
    if(auto_update) this.updateInfo()
  }

  setPartnerName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "partner_name is not a string. Aborted")
      return
    }

    this.partner_name = value
    this.edited = true
    if(auto_update) this.updateInfo()
  }

  setPartnerLink(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "partner_link is not a string. Aborted")
      return
    }

    this.partner_link = value
    this.edited = true
    if(auto_update) this.updateInfo()
  }

  setDescription(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
      return
    }

    this.description = value
    this.edited = true
    if(auto_update) this.updateInfo()
  }

  setType(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "type is not a string. Aborted")
      return
    }

    this.type = value
    this.edited = true
    if(auto_update) this.updateInfo()
  }

  setStuff(value, auto_update = true) {
    // type check
    if(!(value instanceof StuffListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "stuff is not a StuffListController. Aborted")
      return
    }

    this.stuff = value
    this.edited = true
    if(auto_update) this.updateInfo()
  }

  setWork() {
    this.university = false
    this.stuff.setWork()
  }

  setUniversity() {
    this.university = true
    this.stuff.setUniversity()
  }

  // getter
  getId() { return this.id }
  getName() { return this.name }
  getPartnerName() { return this.partner_name }
  getPartnerLink() { return this.partner_link }
  getDescription() { return this.description }
  getType() { return this.type }
  getStuff() { return this.stuff }

  // API
  async create() {
    console.log(this.university)
    let db = new UniversityDB(this.university)
    let result = await db.createArgument({
      name: this.getName(),
      description: this.getDescription(),
      partnerName: this.getPartnerName(),
      partnerLink: this.getPartnerLink(),
    })
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    else this.setId(result.response.slug)

    return result
  }

  async update() {
    console.log(this.university)
    let db = new UniversityDB(this.university)
    let result = await db.updateArgument({
      id: this.getId(),
      name: this.getName(),
      description: this.getDescription(),
      partnerName: this.getPartnerName(),
      partnerLink: this.getPartnerLink(),
      // image: "blob:http://localhost:3000/4106a8d9-320a-4e29-ac16-0af673ae96fa" // TODO
    })

    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new UniversityDB(this.university)
    let result = await db.deleteArgument(this.getId())
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish() {
    let result = {error: false}
    if(this.edited) {
      // call the api
      let result
      if(this.getId() == "") result = await this.create()
      else result = await this.update()
      
      if(result.error) {
        result.message = "Qualcosa è andato storto..."
        return result
      }
    }

    // publish stuff
    result = await this.getStuff().publish(this.getId())
    return result
  }

  loadStuff() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data["id"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("name") && typeof data["name"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }
    if(keys.includes("partner_name") && typeof data["partner_name"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "partner_name is not a string. Aborted")
      return
    }
    if(keys.includes("partner_link") && typeof data["partner_link"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "partner_link is not a string. Aborted")
      return
    }
    if(keys.includes("description") && typeof data["description"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
      return
    }
    if(keys.includes("type") && typeof data["type"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "type is not a string. Aborted")
      return
    }

    // loading data
    if(keys.includes('stuff')) {
      this.stuff.buildFromExport(data['stuff'])
      delete data['stuff']
    } 
    
    Object.assign(this, data)
    this.updateInfo()
  }
}

export default TopicController