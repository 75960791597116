import Database, { dev_mode } from "./database"

class UserDB {
  constructor() {
    this.db = new Database()
  }

  async login(emailAddress, password) {
    let result = await this.db.post({
      path: dev_mode ? "/auth/login_app" : "/auth/login_web",
      data: {
        emailAddress: emailAddress,
        password: password
      },
    })
      if(result.error) return result
      else {
        if(dev_mode) {
          sessionStorage.setItem("access_token", result.response['access_token'])
          sessionStorage.setItem("refresh_token", result.response['refresh_token'])
        }
        return {error: false}
    }
  }

  async getData() {
    return await this.db.get({path: "/user"})
  }

  async updateData(data) {
    // type check
    if(Object.keys(data) == undefined) {
      console.warn(`[ ${this.constructor.name}] `,"data is not an object. Aborted")
      return
    }

    // checking fields
    let accepted_field = ["name", "surname", "emailAddress", "birthDay", "telephoneNumber"]
    let keys = Object.keys(data)
    for(let key of keys) {
      if(!accepted_field.includes(key)) {
        console.warn(`[ ${this.constructor.name}] `, key + " is not a valid field. It will not send")
        delete data[key]
      }
    }

    // sending data
    this.db.put({
      path: "/user",
      data: data,
    })
  }
}

export default UserDB