import MyComponent from "../../../base_components/my_component";
import QuestionsListController from "./questions_list_controller";
import TopicController from "./topic_controller";
import _ from "lodash"
import QuizDB from "../../database/education/school_challenge/quiz";


class QuizController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.name = ""
    this.position = 0
    this.partner_link = ""
    this.topic = new TopicController()
    this.questions = new QuestionsListController()
    this.questions.setOverrideState((() => this.updateInfo()).bind(this))
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()

    // setting API operation 
    this.operations = []
  }

  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    if(auto_update) this.updateInfo()
  }

  setPosition(value, auto_update = true) {
    // type check
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    this.position = value
    if(auto_update) this.updateInfo()
  }

  setPartnerLink(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "partner_link is not a string. Aborted")
      return
    }

    this.partner_link = value
    if(auto_update) this.updateInfo()
  }

  setTopic(value, auto_update = true) {
    // type check
    if(value instanceof QuestionsListController) {
      console.warn(`[ ${this.constructor.name} ]`, "topic is not a TopicController instance. Aborted")
      return
    }

    this.topic = value
    if(auto_update) this.updateInfo()
  }

  setQuestions(value, auto_update = true) {
    // type check
    if(!(value instanceof QuestionsListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "questions is not a QuestionsListController instance. Aborted")
      return
    }

    this.questions = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getName() { return this.name }
  getTopic() { return this.topic }
  getPartnerLink() { return this.partner_link }
  getQuestions() { return this.questions }
  getPosition() { return this.position }

  // API
  async create(argument_slug) {
    let db = new QuizDB()
    let result = db.createQuiz({
      name: this.getName(),
      position: this.getPosition(),
      partner_link: this.getPartnerLink()
    }, argument_slug)  
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async update() {
    let db = new QuizDB()
    let result = db.updateQuiz({
      id: this.getId(),
      name: this.getName(),
      position: this.getPosition(),
      partner_link: this.getPartnerLink()
    })  
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new QuizDB()
    let result = await db.deleteQuiz(this.getId())  
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish(argument_slug) {
    if(this.getId() == "") return await this.create(argument_slug)
    else return await this.update()
  }

  load() {}

  loadTopic() {}

  loadQuestions() {}

  linkQuestion(question) {}

  addQuestion(question = {}) {}

  removeQuestion() {
    // let link_question_opeation = this.operations.findIndex(operation => operation.name == _link_question_operation_tag &&
      // operation.params.question == question)
  }

  checkChildren() {
    // TODO: to implement
  }

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data['id'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("name") && typeof data['name'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }
    
    // loading data
    if(keys.includes("topic")) {
      this.topic.buildFromExport(data['topic'])
      delete data['topic']
    }
    if(keys.includes("questions")) {
      this.questions.buildFromExport(data['questions'])
      delete data['questions']
    }

    Object.assign(this, data)
    this.updateInfo()
  }

}

export default QuizController