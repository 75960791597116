import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router'
import QuizController from '../../../../controllers/education/school_challenge/quiz_controller'
import QuizListController from '../../../../controllers/education/school_challenge/quiz_list_controller'
import routes from '../../../../router/routes'
import { Context } from "../../../../App"
import { Button } from 'primereact/button'
import MyTable from '../../../../components/my_table/my_table'
import EditCell from '../../../../components/edit_cell'
import Popup from '../../../../components/popup'
import PopupFooter from '../../../../components/popup_footer'
import { InputText } from 'primereact/inputtext'
import { toInteger } from 'lodash'


function QuizPage() {
  const [popupVisible, setPopupVisible] = useState(false)
  const [selectedQuiz, setSelectedQuiz] = useState(new QuizController())
  const [quizList, setQuizList] = useState(new QuizListController())

  let { state } = useLocation()
  let showMessage = useContext(Context)
  let navigate = useNavigate()
  let params = useParams()

  let argument = params.argument
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / quizList.getPageSize()) + 1)
  
  function checkRequiredFields() {
    let message = []
    if(selectedQuiz.getName() == "") message.push("Nome")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading() {
    let result = await quizList.load(argument, page)
    if(result.error) showMessage(true, result.message)
  }

  async function saveQuiz() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedQuiz.publish(argument)
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        selectedQuiz.reset()
        quizList.reset()
        loading()
        setPopupVisible(false)
      }
    }
  }

  function editFunction(quiz) {
    selectedQuiz.buildFromExport(quiz.export())
    setPopupVisible(true)
  }

  async function deleteQuiz(quiz) {
    let result = await quiz.delete()
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Operazione completata")
      quizList.reset()
      loading()
    }
  }

  useEffect(() => {
    if(argument == undefined) navigate(routes.school_challenge) 
    else {
      selectedQuiz.setState(setSelectedQuiz)
      quizList.setState(setQuizList)

      loading()
    }
  }, [page])
  
  return (
    <div id={"school_challenge_quiz"}>
      <div className="flex justify-content-between">
        <h1>Education - Quiz</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3>Quiz</h3>

      <MyTable 
      first={first}
      values={quizList.getItemsPerPage(page)}
      totalRecords={quizList.getTotalItems()}
      itemsPerPage={quizList.getPageSize()}
      onRowSelection={({ value }) => navigate(routes.school_challenge_question + "/" + value.getId())}
      columns={[
        {field: "position", header: "Posizione"},
        {field: "name", header: "Nome"},
        {field: "partner_link", header: "Link Partner"},
        {body: (quiz) => <EditCell item={quiz} editFunction={editFunction} removeFunction={deleteQuiz}/>}
      ]}
      />

      <Popup
      title={"Crea Quiz"}
      visible={popupVisible}
      setVisible={setPopupVisible}
      closeFunction={() => selectedQuiz.reset()}
      footer={<PopupFooter saveFunction={saveQuiz}/>}
      >
        <label>Nome</label>
        <InputText placeholder={"Nome"} className="w-full mt-2" value={selectedQuiz.getName()} onChange={(e) => selectedQuiz.setName(e.target.value)} /><br/><br/>
        <label>Partener Link</label>
        <InputText placeholder={"Partner Link"} className="w-full mt-2" value={selectedQuiz.getPartnerLink()} onChange={(e) => selectedQuiz.setPartnerLink(e.target.value)} /><br/><br/>
        <label>Posizione</label>
        <InputText placeholder={"Partner Link"} className="w-full mt-2" value={selectedQuiz.getPosition()} onChange={(e) => selectedQuiz.setPosition(toInteger(e.target.value))} /><br/><br/>
      </Popup>
    </div>
  )
}

export default QuizPage