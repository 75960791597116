import Database from "../../database";

class ArgumentDB {
  constructor() {
    this.db = new Database()
  }

  typeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }  
    }
    if(keys.includes('name')) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }  
    }
    if(keys.includes('position')) {
      if(typeof data['position'] != "number") {
        console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
        return false
      }  
    }

    return true
  }

  async getAllArguments(subject_slug, page, page_size) {
    if(typeof subject_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "subject_slug is not a string. Aborted")
      return false
    }
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return false
    }
    if(typeof page_size != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page_size is not a number. Aborted")
      return false
    }

    let result = await this.db.get({
      path: `/gamification/quiz/subject/${subject_slug}/arguments?page_number=${page}&page_size=${page_size}`
    })
    return result
  }

  async createArgument(data, subject_slug) {
    if(typeof subject_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "subject_slug is not a string. Aborted")
      return false
    }

    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let result = await this.db.post({
        path: `/gamification/quiz/subject/${subject_slug}/arguments`,
        data: {
          name: data['name'],
          order: data['position']
        }
      })
      return result
    }
  }

  async updateArgument(data) {
    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let result = await this.db.put({
        path: `/gamification/quiz/argument/${data['id']}`,
        data: {
          name: data['name'],
          order: data['position']
        }
      })
      return result
    }
  }

  async deleteArgument(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "slug is not a string. Aborted")
      return false
    }

    let result  =await this.db.delete({
      path: `/gamification/quiz/argument/${slug}`
    })
    return result
  }
}

export default ArgumentDB