import React, { useEffect, useContext, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useLoaderData, useLocation, useNavigate } from 'react-router';
import routes from '../../../router/routes';
import TopicsListController from '../../../controllers/orientation/topics_list_controller';
import { Paginator } from 'primereact/paginator';
import "./style.css"
import { confirmPopup } from 'primereact/confirmpopup';
import { Context } from "../../../App"
import MyTable from '../../../components/my_table/my_table';

function WorkOrientation() {
  let navigate = useNavigate()
  let { state } = useLocation()

  let first = state != undefined ? state['first'] : 1
  const [topics, setTopics] = useState(new TopicsListController())
  const page = Math.floor(first / topics.getItemsPerPage()) + 1

  function editTopic(data) {
    let topic = data.value
    navigate(routes.editor_orientation, {state: {origin: "work", topic: topic.toString()}})
  }

  useEffect(() => {
    async function loading(page) {
      await topics.load(page)
    }

    topics.setState(setTopics)
    topics.setWork()
    loading(page)
  }, [first])
  
  return (
    <div id="work_page">
      <div className="flex justify-content-between">
        <h1>Orientamento Lavorativo</h1>
        <div className="flex align-items-center"><Button onClick={() => navigate(routes.editor_orientation, {state: {"origin": "work"}})} label="Crea" /></div>
      </div>
      <h3>Argomenti</h3>
      <MyTable
      first={first}
      values={topics.getItemsWindow(page)}
      totalRecords={topics.getTotalItems()}
      onRowSelection={editTopic}
      itemsPerPage={topics.getItemsPerPage()}
      columns={[
        {field: "name", header: "Nome"},
        {field: "partner_name", header: "Nome partner"},
        {field: "partner_link", header: "Link Partner"},
        // {field: "description", header: "Descrizione"},
        {body: (...props) => <EditCell topics={topics} {...props}/>}
      ]}
      />
    </div>
  )
}

function EditCell(props) {
  let topic = props[0]
  let topics = props['topics']
  const showMessage = useContext(Context)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        let result = await topic.delete()
        if(result.error) showMessage(true, result.message)
        else {
          topics.deleteTopic(topic.getId())
          showMessage(false, "Eliminazione completata")
        }
      }
    })
  }
  
  return (
    <div className="edit_cell">
      <Button
      icon="pi pi-trash"
      text severity="danger"
      aria-label="Cancel"
      onClick={remove}/>
    </div>
  )
}

export default WorkOrientation