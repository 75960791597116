import { findByAltText } from "@testing-library/react";
import MyComponent from "../../../base_components/my_component";
import SubjectDB from "../../database/education/quiz/subject";
import TopicsListController from "./topics_list_controller";

class SubjectController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.name = ""
    this.image = ""
    this.topics = new TopicsListController()
    this.topics.setOverrideState((() => this.updateInfo()).bind(this))
  }

  // setter 
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }
  
  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    if(auto_update) this.updateInfo()
  }

  setImage(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }

    this.image = value
    if(auto_update) this.updateInfo()
  }

  setTopics(value, auto_update = true) {
    // type check
    if(!(value instanceof TopicsListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "macro_topics is not a TopicController instance. Aborted")
      return
    }

    this.topics = value
    if(auto_update) this.updateInfo()
  }


  // getter
  getId() { return this.id }
  getName() { return this.name }
  getImage() { return this.image }
  getTopics() { return this.topics }

  // API
  async create() {
    let db = new SubjectDB()
    let result = await db.createSubject({
      name: this.getName(),
      image: this.getImage()
    })
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async update() {
    let db = new SubjectDB()
    let result = await db.updateSubject({
      id: this.getId(),
      name: this.getName(),
      image: this.getImage()
    })
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new SubjectDB()
    let result = await db.deleteSubject(this.getId())
    if(result.error) result['message'] = "Qualcosa è andato storto..."
    return result
  }

  async publish() {
    if(this.getId() == "") return await this.create()
    else return await this.update()
  }

  load() {}

  loadTopics() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data['id'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not not a string. Aborted")
      return
    }
    if(keys.includes("name") && typeof data['name'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not not a string. Aborted")
      return
    }
    if(keys.includes("image") && typeof data['image'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not not a string. Aborted")
      return
    }

    // loading data
    if(keys.includes("topics")) {
      this.topics.buildFromExport(data['topics'])
      delete data['topics']
    }

    Object.assign(this, data)
    this.updateInfo()
  }
}

export default SubjectController