import { isArguments } from "lodash";
import MyComponent from "../../../base_components/my_component";
import LessonsDB from "../../database/education/learn/lesson";
import PagesListController from "./pages_list_controller";
import TopicController from "./topic_controller";

class LessonController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.name = ""
    this.position = 0
    this.pages = new PagesListController()
    this.topic = new TopicController()
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    if(auto_update) this.updateInfo()
  }

  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    if(auto_update) this.updateInfo()
      
  }

  setPosition(value, auto_update = true) {
    // type check
    if(typeof value != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    this.position = value
    if(auto_update) this.updateInfo()
  }

  setPages(value, auto_update = true) {
    // type check
    if(!(value instanceof PagesListController)) {
      console.warn(`[ ${this.constructor.name} ]`, "pages is not a PagesListController instance. Aborted")
      return
    }

    this.pages = value
    if(auto_update) this.updateInfo()
  }

  setTopic(value, auto_update = true) {
    // type check
    if(!(value instanceof TopicController)) {
      console.warn(`[ ${this.constructor.name} ]`, "topic is not an instance of TopicController. Aborted")
      return
    }

    this.topic = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getId() { return this.id }
  getName() {return this.name }
  getPosition() { return this.position }
  getPages() { return this.pages }
  getTopic() { return this.topic }

  // API
  async create(argument_slug) {
    let db = new LessonsDB()
    let result = await db.createLesson({
      name: this.getName(),
      position: this.getPosition()
    }, argument_slug)
    if(result.error) result['message'] = "Qualcosa è anadato storo..."
    return result
  }

  async update() {
    let db = new LessonsDB()
    let result = await db.updateLesson({
      id: this.getId(),
      name: this.getName(),
      position: this.getPosition()
    })
    if(result.error) result['message'] = "Qualcosa è anadato storo..."
    return result
  }

  async delete() {
    let db = new LessonsDB()
    let result = await db.deleteLesson(this.getId())
    if(result.error) result['message'] = "Qualcosa è anadato storo..."
    return result
  }

  async publish(argument_slug) {
    if(this.getId() == "") return await this.create(argument_slug)
    else return await this.update()
  }

  load() {}

  loadPages() {}

  loadTopic() {}

  // management

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('id') && typeof data['id'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes('name') && typeof data['name'] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }
    if(keys.includes('position') && typeof data['position'] != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    }

    // loading data
    if(keys.includes('topic')) {
      this.topic.buildFromExport(data['topic'])
      delete data['topic']
    }
    if(keys.includes('pages')) {
      this.pages.buildFromExport(data['pages'])
      delete data['pages']
    }

    Object.assign(this, data)
    this.updateInfo()
  }
}

export default LessonController