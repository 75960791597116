import MyComponent from "../../../base_components/my_component";
import SubjectDB from "../../database/education/quiz/subject";
import SubjectController from "./subject_controller";

class SubjectsListController extends MyComponent {
  constructor() {
    super()
    this.list = []
    this.page_size = 10
    this.total_items = 0
    this.page_index_relation = {}
  }

  // setter
  setList(value, auto_update = true) {
      if(!Array.isArray(value)) {
        console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
        return
      }

      this.list = value 
      if(auto_update) this.updateInfo()
  }

  // getter
  getList(value, auto_update = true) { return this.value }
  getTotalItems() { return this.total_items }
  getPageSize() { return this.page_size }

  getItemsPerWindow(page) {
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return
    }
    if(Object.keys(this.page_index_relation).includes(page.toString())) {
      let start = this.page_index_relation[page]['start']
      let end = this.page_index_relation[page]['end']
      let window = this.list.slice(start, end)
      return window
    } else return []
  }

  // API
  async load(page) {
    let result = {error: false}
    if(!Object.keys(this.page_index_relation).includes(page.toString())) {
      let db = new SubjectDB()
      result = await db.getAllSubjects(page, this.page_size)
      if(result.error) result['message'] = "Qualcosa è andato storto"
      else {
        let items = result.response.items
        this.total_items = result.response.total_items
        this.page_index_relation[page] = {start: this.list.length, end: this.list.length + items.length}

        for(let data of items) {
          this.addSubject({
            id: data['slug'],
            name: data['name'],
            image: data['image']
          }, false)
        }

        this.updateInfo()
      }
    }
    return result
  }

  // management
  addSubject(subject, auto_update = true) {
    if(Array.isArray(subject)) {
      console.warn(`[ ${this.constructor.name} ]`, "subject is a list. Aborted")
      return
    } else if(subject instanceof SubjectController) {
      this.list.push(subject)
    } else if(typeof subject == "object") {
      // creating subject instance
      let newSubject = new SubjectController()
      newSubject.setOverrideState((() => this.updateInfo()).bind(this))
      newSubject.buildFromExport(subject)   // all the correctness aspects are inside the function
      this.list.push(newSubject)
    }

    if(auto_update) this.updateInfo()
  }

  deleteSubject(subject) {
    if(typeof subject == "string") {
      // subject is the subject id
      let subjectId = subject
      subject = this.list.find(item => item.getId() == subjectId)
      let index = this.list.indexOf(subject)
      subject.delete()
      this.list.splice(index, 1)
    } else if(subject instanceof SubjectController) {
      // subject is an instance of SubjectController
      let index = this.list.indexOf(subject)
      subject.delete()
      this.list.splice(index, 1)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "subject is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not in a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof SubjectController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item of the list is not in a SubjectController instance. Aborted")
          return
        }
      }
    }

    Object.assign(this, data)
    this.updateInfo()
  }

}

export default SubjectsListController