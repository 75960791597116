import OrderedList from "../../../base_components/ordered_list"
import ArgumentDB from "../../database/education/quiz/argument"
import TopicController from "./topic_controller"

class TopicsListController extends OrderedList {
  constructor() {
    super()
    this.position_field_name = "position"
    this.page_size = 10
    this.total_items = 0
    this.page_index_relation = {}
  }

  getTotalItems() { return this.total_items }
  getPageSize() { return this.page_size }

  getItemsPerPage(page) {
    if(typeof page != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page is not a number. Aborted")
      return
    }

    if(Object.keys(this.page_index_relation).includes(page.toString())) {
      let start = this.page_index_relation[page]['start']
      let end = this.page_index_relation[page]['end']
      let window = this.list.slice(start, end)
      return window
    } else return []
  }

  async load(subject_slug, page) {
    let result = {error: false}

    if(!Object.keys(this.page_index_relation).includes(page.toString())) {
      let db = new ArgumentDB()
      result = await db.getAllArguments(subject_slug, page, this.page_size)
      if(result.error) result['message'] = "Qualcosa è andato storto"
      else {
        let items = result.response.items
        this.total_items = result.response.total_items
        this.page_index_relation[page] = {start: this.list.length, end: this.list.length + items.length}
  
        for(let data of items) {
          this.addTopic({
            id: data['slug'],
            name: data['name'],
            position: data['order']
          }, false)
        }
  
        this.updateInfo()
      }
    }
    return result
  }

  addTopic(topic, auto_update = true) {
    if(Array.isArray(topic)) {
      console.warn(`[ ${this.constructor.name} ]`, "topic is a list. Aborted")
      return
    } else if(topic instanceof TopicController) {
      this.list.push(topic)
    } else if(typeof topic == "object") {
      // creating topic instance
      let newtopic = new TopicController()
      newtopic.setOverrideState((() => this.updateInfo()).bind(this))
      newtopic.buildFromExport(topic)   // all the correctness aspects are inside the function
      this.list.push(newtopic)
    }

    if(auto_update) this.updateInfo()
  }

  deleteTopic(topic) {
    if(typeof topic == "string") {
      // topic is the topic id
      let topicId = topic
      topic = this.list.find(item => item.getId() == topicId)
      this.removeItem(topic)
    } else if(topic instanceof TopicController) {
      // topic is an instance of TopicController
      let index = this.list.indexOf(topic)
      this.removeItem(topic)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "topic is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes('list') && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not in a list. Aborted")
      return
    }
    if(keys.includes('list')) {
      for(let element of data['list']) {
        if(!(element instanceof TopicController)) {
          console.warn(`[ ${this.constructor.name} ]`, "an item of the list is not in a QuestionController instance. Aborted")
          return
        }
      }
    }

    Object.assign(this, data)
    this.updateInfo()
  }

}

export default TopicsListController