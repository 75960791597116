import React, { useRef, useContext, createContext } from 'react';
import { RouterProvider, MemoryRouter } from 'react-router';
import { Toast } from "primereact/toast"
import router from "./router/root"
import "/node_modules/primeflex/primeflex.css"
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";      
import { ConfirmPopup } from 'primereact/confirmpopup';

let Context = createContext(null)

function App() {
  const toast = useRef(null)

  function showMessage(error, message) {
    toast.current.show({severity: error ? "error" : "success", summary: error ? "Errore" : "Perfetto!", detail: message})
  }  

  return (
    <div className="App">
      <Context.Provider value={showMessage}>
        <RouterProvider router={router}/>
        <Toast ref={toast} position={'bottom-center'} />
        <ConfirmPopup />
      </Context.Provider>
    </div>
  );
}

export { Context }
export default App