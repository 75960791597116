import Home from "../pages/home";
import news_route from "./news"
import routes from "./routes";
import university_orientation from "./university_orientation"
import work_orientation from "./work_orientation"
import editor_oreintation from "./editor_orientation"
import education_learn from "./learn"
import education_learn_macro_argument from "./learn_macro_argument"
import education_learn_argument from "./learn_argument"
import education_learn_lessons from "./learn_lessons"
import learn_pages from "./learn_pages"
import education_quiz from "./quiz_subject"
import education_quiz_argument from "./quiz_argument"
import education_quiz_quiz from "./quiz_quiz"
import education_quiz__question from "./quiz_question"
import education_school_challenge from "./school_challenge"
import education_school_challenge_quiz from "./school_challenge_quiz"
import education_school_challenge_question from "./school_challenge_question"

let route = {
  path: routes.home,
  element: <Home />,
  children: [
    news_route,
    university_orientation,
    // orientation
    work_orientation,
    editor_oreintation,
    // education
    education_learn,
    education_learn_macro_argument,
    education_learn_argument,
    education_learn_lessons,
    learn_pages,
    education_quiz,
    education_quiz_argument,
    education_quiz_quiz,
    education_quiz__question,
    education_school_challenge,
    education_school_challenge_quiz,
    education_school_challenge_question,
  ]
}
export default route