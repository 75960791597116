import MyComponent from "../../base_components/my_component";
import TopicController from "./topic_controller";
import UniversityDB from "../database/orientation/university";

export const _normal_status = 0
export const _create_status = 1
export const _edit_status = 2
export const _delete_status = 3

class StuffController extends MyComponent {
  constructor() {
    super()
    this.id = ""
    this.image = ""
    this.name = ""
    this.content = ""
    this.call_to_action = ""
    this.topic = new TopicController()
    this.status = _create_status
    this.topic.setOverrideState((() => this.updateInfo()).bind(this))
    this.university = true
  }

  // setter
  setId(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }

    this.id = value
    this.status = _normal_status
    if(auto_update) this.updateInfo()
  }

  setImage(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }

    this.image = value
    this.status = this.status == _normal_status ? _edit_status : this.status
    if(auto_update) this.updateInfo()
  }

  setName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
      return
    }

    this.name = value
    this.status = this.status == _normal_status ? _edit_status : this.status
    if(auto_update) this.updateInfo()
  }

  setContent(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "content is not a string. Aborted")
      return
    }

    this.content = value
    this.status = this.status == _normal_status ? _edit_status : this.status
    if(auto_update) this.updateInfo()
  }

  setCallToAction(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "call_to_action is not a string. Aborted")
      return
    }

    this.call_to_action = value
    this.status = this.status == _normal_status ? _edit_status : this.status
    if(auto_update) this.updateInfo()
  }

  setTopic(value, auto_update = true) {
    // type check
    if(!(value instanceof TopicController)) {
      console.warn(`[ ${this.constructor.name} ]`, "topic is not a string. Aborted")
      return
    }

    this.topic = value
    if(auto_update) this.updateInfo()
  }

  setUniversity() { 
    this.university = true
  }
  
  setWork() { this.university = false }

  // getter
  getId() { return this.id }
  getImage() { return this.image }
  getName() { return this.name }
  getContent() { return this.content }
  getCallToAction() { return this.call_to_action }
  getTopic() { return this.topic }

  // API
  async create(topic_slug) {
    let db = new UniversityDB(this.university)
    let result = await db.createStuff({
      name: this.getName(),
      content: this.getContent(),
      call_to_action: this.getCallToAction(),
      image: this.getImage(),
    }, topic_slug)
    if(result.error) result.message = "Qualcosa è andato storto..."
    return result
  }
  
  async update() {
    let db = new UniversityDB(this.university)
    let result = await db.editStuff({
      id: this.getId(),
      name: this.getName(),
      content: this.getContent(),
      call_to_action: this.getCallToAction(),
      image: this.getImage(),
    })
    if(result.error) result.message = "Qualcosa è andato storto..."
    return result
  }

  async delete() {
    let db = new UniversityDB(this.university)
    let result = await db.deleteStuff(this.getId())
    if(result.error) result.message = "Qualcosa è andato storto..."
    return result
  }

  async publish(topic_slug) {
    let result = {error: false}
    if(this.status == _create_status) result = await this.create(topic_slug)
    else if(this.status == _edit_status) result = await this.update()
    else if(this.status == _delete_status) result = await this.delete()
    return result
  }

  load() {}

  loadTopic() {}

  // management
  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }

    // type check
    let keys = Object.keys(data)
    if(keys.includes("id") && typeof data["id"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    if(keys.includes("image") && typeof data["image"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
      return
    }
    if(keys.includes("content") && typeof data["content"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "content is not a string. Aborted")
      return
    }
    if(keys.includes("call_to_action") && typeof data["call_to_action"] != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "call_to_action is not a string. Aborted")
      return
    }

    // loading data
    if(keys.includes("topic")) {
      this.topic.buildFromExport(data['topic'])
      delete data['topic']
    }
    Object.assign(this, data)

    if(this.getId() != "") this.status = _normal_status
    this.updateInfo()
  }
}

export default StuffController