import Database from "../../database";

class LessonsDB {
  constructor() {
    this.db =new Database()
  }

  typeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('name')) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('position')) {
      if(typeof data['position'] != "number") {
        console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
        return false
      }
    }
    return true
  }

  async getAllLessons(argument_slug, page_number, page_size) {
    if(typeof argument_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "argument_slug is not a string. Aborted")
      return
    }
    if(typeof page_number != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page_number is not a number. Aborted")
      return
    }
    if(typeof page_size != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "page_size is not a number. Aborted")
      return
    }

    let result = await this.db.get({
      path: `/gamification/learn/argument/${argument_slug}/lessons?page_number=${page_number}&page_size=${page_size}`
    })
    return result
  }

  async createLesson(data, argument_slug) {
    if(typeof argument_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "argument_slug is not a string. Aborted")
      return
    }

    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let result = await this.db.post({
        path: `/gamification/learn/argument/${argument_slug}/lessons`,
        data: {
          name: data['name'],
          order: data['position']
        }
      })

      return result
    }
  }

  async updateLesson(data) {
    let isCorrect = this.typeCheck(data)
    if(isCorrect) {
      let result = await this.db.put({
        path: `/gamification/learn/lesson/` + data['id'],
        data: {
          name: data['name'],
          order: data['position']
        }
      })

      return result
    }
  }

  async deleteLesson(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "slug is not a string. Aborted")
      return
    }

    let result = this.db.delete({
      path: `/gamification/learn/lesson/` + slug
    })
    return result
  } 
}

export default LessonsDB