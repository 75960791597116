import Database from "../database"

class UniversityDB {
  constructor(university = true) {
    this.db = new Database()
    this.type = !university ? "working" : "university"
  }

  // ARGUMENTS

  argumentTypeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('name')) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('description')) {
      if(typeof data['description'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "description is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('partnerName')) {
      if(typeof data['partnerName'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "partnerName is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('partnerLink')) {
      if(typeof data['partnerLink'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "partnerLink is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('image')) {
      if(typeof data['image'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
        return false 
      }
    }
    return true
  }

  // arguments
  async getArgument(slug) {
    if(typeof slug != 'string') {
      console.warn(`[ ${this.constructor.name}] `,"slug is not an string. Aborted")
      return
    }

    this.db.get({
      path: `/orientation/${this.type}/argument/${slug}`
    })
  }

  async getAllArguments(page_number, page_size = 10) {
    // TODO: type check
    return await this.db.get({
      path: `/orientation/${this.type}/arguments?page_number=${page_number}&page_size=${page_size}`
    })
  }

  async createArgument(data) {
    let isCorrect = this.argumentTypeCheck(data)
    if(isCorrect) {
      return await this.db.post({
        path: `/orientation/${this.type}/arguments`,
        data: {
          name: data['name'],
          description: data['description'],
          partnerName: data['partnerName'],
          partnerLink: data['partnerLink'],
          image: data['image'],
        }
      })
    } else return
  } 

  async updateArgument(data) {
    let isCorrect = this.argumentTypeCheck(data)
    if(isCorrect) {
      // make request
      return await this.db.put({
        path: `/orientation/${this.type}/argument/` + data['id'],
        data: {
          name: data['name'],
          description: data['description'],
          partnerName: data['partnerName'],
          partnerLink: data['partnerLink'],
          image: data['image'],
        }
      })
    } else return
  }

  async deleteArgument(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
      return
    }
    
    let result = await this.db.delete({
      path: `/orientation/${this.type}/argument/${slug}`
    })

    return result
  }

  // STUFF
  stuffTypeCheck(data) {
    let keys = Object.keys(data)
    if(keys.includes('id')) {
      if(typeof data['id'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "id is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('image')) {
      if(typeof data['image'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "image is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('name')) {
      if(typeof data['name'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "name is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('content')) {
      if(typeof data['content'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "content is not a string. Aborted")
        return false
      }
    }
    if(keys.includes('call_to_action')) {
      if(typeof data['call_to_action'] != "string") {
        console.warn(`[ ${this.constructor.name} ]`, "call_to_action is not a string. Aborted")
        return false
      }
    }
    return true
  }

  async createStuff(data, topic_slug) {
    if(typeof topic_slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "topic_slug is not a string. Aborted")
      return
    }
    let isCorrect = this.stuffTypeCheck(data)
    if(isCorrect) {
      let imageFile = await fetch(data['image'])
      .then((data) => data.blob())
      .then(blob => {
        const file = new File([blob], 'image.png', { type: 'image/png' });
        return file
      })
      let formData = new FormData()
      formData.append("name", data['name'])
      formData.append("text", data['content'])
      formData.append("link", data['call_to_action'])
      formData.append("image", imageFile)
      
      let result = await this.db.post({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/orientation/${this.type}/argument/` + topic_slug + "/contents",
        data: formData
      })

      return result
    } else return
  }

  async editStuff(data) {
    let isCorrect = this.stuffTypeCheck(data)
    if(isCorrect) {
      let formData = new FormData()
      if(data['image'].substring(0, 4) == "blob") {
        let imageFile = await fetch(data['image'])
        .then((data) => data.blob())
        .then(blob => {
          const file = new File([blob], 'image.png', { type: 'image/png' });
          return file
        })
        formData.append("image", imageFile)
      }
      formData.append("name", data['name'])
      formData.append("text", data['content'])
      formData.append("link", data['call_to_action'])

      let result = await this.db.put({
        headers: {"Content-Type": "multipart/form-data"},
        path: `/orientation/${this.type}/content/` + data['id'],
        data: formData
      })
      return result
    } else return
  }

  async deleteStuff(slug) {
    if(typeof slug != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "topic_slug is not a string. Aborted")
      return
    }
    let result = await this.db.delete({
      path: `/orientation/${this.type}/content/` + slug
    })
    return result
  }
}

export default UniversityDB