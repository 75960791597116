import MyComponent from "./my_component"

class OrderedList extends MyComponent{
  constructor() {
    super()
    this.list = []
    this.position_field_name = "" // used to identify the position field name of the class that is extending OrderedList
  }

  // setter
  setList(value, auto_update = true) {
    // type check
    if(!Array.isArray(value)) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
      return
    }

    this.list = value
    if(auto_update) this.updateInfo()
  }

  setPositionFieldName(value, auto_update = true) {
    // type check
    if(typeof value != "string") {
      console.warn(`[ ${this.constructor.name} ]`, "position_field_name is not a string. Aborted")
      return
    }

    this.position_field_name = value
    if(auto_update) this.updateInfo()
  }

  // getter
  getList(){ return this.list }
  getPositionFieldName(){ return this.position_field_name }

  // used to order the list
  makeOrder() {
    this.list.sort((a,b) => (a[this.position_field_name] > b[this.position_field_name]) ? 1 : ((b[this.position_field_name] > a[this.position_field_name]) ? -1 : 0))
  }

  // used to switch the order of the items inside the list
  switchItems(item_1, item_2) {
    // type check
    if(this.position_field_name == "") {
      console.warn(`[ ${this.constructor.name} ]`, "You didn't set the position field name. Aborted")
      return
    }
    else if(this.list.indexOf(item_1) == -1) {
      console.warn(`[ ${this.constructor.name} ]`, `${item_1} not found in the list. Aborted`)
      return
    }
    else if(this.list.indexOf(item_2) == -1) {
      console.warn(`[ ${this.constructor.name} ]`, `${item_2} not found in the list. Aborted`)
      return
    }

    // switching elements
    let index = this.list.indexOf(item_1)
    item_1 = this.list[index]
    index = this.list.indexOf(item_2)
    item_2 = this.list[index]
    let position_1 = item_1[this.position_field_name]
    item_1[this.position_field_name] = item_2[this.position_field_name]
    item_2[this.position_field_name] = position_1
    console.log(item_1, item_2)
    this.makeOrder()
    this.updateInfo()
  }

  // used to change the order of one item inside the list
  changeOrder(item, position) {
    // type check
    if(this.position_field_name == ""){
      console.warn(`[ ${this.constructor.name} ]`, "You didn't pass the position field name")
      return
    } else if(this.list.indexOf(item) == -1) {
      console.warn(`[ ${this.constructor.name} ]`, `${item} not found in the list. Aborted`)
      return
    } else if(typeof position != "number") {
      console.warn(`[ ${this.constructor.name} ]`, "position is not a number. Aborted")
      return
    } else if(position < 0) {
      console.warn(`[ ${this.constructor.name} ]`, "position not valid( < 0 ). Aborted")
      return 
    }

    let index = this.list.indexOf(item)
    item = this.list[index]
    item[this.position_field_name] = position
    this.makeOrder()
    this.updateInfo()
    // you have to call this.updateInfo() when you call this function
  }

  addItem(item) {
    // type check
    if(this.position_field_name == "") {
      console.warn(`[ ${this.constructor.name} ]`, "You didn't pass the position field name")
      return
    } 

    // put the element in the right position
    let index_on_list = 0

    for(let element of this.list)
      if(element[this.position_field_name] < item[this.position_field_name])
        index_on_list += 1
      else if(element[this.position_field_name] == item[this.position_field_name])
        console.warn(`[ ${this.constructor.name} ]`, "Two items with the same position")

    this.list.splice(index_on_list, 0, item)
    this.updateInfo()
  }

  removeItem(item) {
    // type check
    if(this.position_field_name == "") {
      console.warn(`[ ${this.constructor.name} ]`, "You didn't pass the position field name")
      return
    }
    else if(this.list.indexOf(item) == -1) {
      console.warn(`[ ${this.constructor.name} ]`, `${item} not found in the list. Aborted`)
      return
    }

    let index = this.list.indexOf(item);
    if (index > -1) {
      this.list.splice(index, 1);
    }
    this.updateInfo()
  }
}

export default OrderedList