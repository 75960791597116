import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'
import TopicsListController from '../../../../controllers/education/school_challenge/topics_list_controller'
import TopicController from '../../../../controllers/education/school_challenge/topic_controller'
import { Context } from "../../../../App"
import { Button } from 'primereact/button'
import MyTable from '../../../../components/my_table/my_table'
import { Avatar } from 'primereact/avatar'
import EditCell from '../../../../components/edit_cell'
import Popup from '../../../../components/popup'
import PopupFooter from '../../../../components/popup_footer'
import ImagePicker from '../../../../components/image_picker'
import { InputText } from 'primereact/inputtext'
import routes from '../../../../router/routes'

function SchoolChallenge() {
  const [selectedArgument, setSelectedArgument] = useState(new TopicController())
  const [argumentsList, setArgumentsList] = useState(new TopicsListController())
  const [popupVisible, setPopupVisible] = useState(false)

  let { state } = useLocation()
  let showMessage = useContext(Context)
  let navigate = useNavigate()

  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / argumentsList.getPageSize()) + 1)
  
  function checkRequiredFields() {
    let message = []
    if(selectedArgument.getImage() == "") message.push("Immagine")
    if(selectedArgument.getName() == "") message.push("Nome")
    if(selectedArgument.getDescription() == "") message.push("Descrizione")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading() {
    let result = await argumentsList.load(page)
    if(result.error) showMessage(true, result.message)
  }

  async function saveArgument() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedArgument.publish()
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        selectedArgument.reset()
        argumentsList.reset()
        setPopupVisible(false)
        loading()
      }
    }
  }

  async function editFunction(argument) {
    selectedArgument.buildFromExport(argument.export())
    setPopupVisible(true)
  }

  async function removeArgument(argument) {
    let result = await argument.delete()
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Operazione completata")
      argumentsList.reset()
      loading()
    }
  }

  useEffect(() => {
    selectedArgument.setState(setSelectedArgument)
    argumentsList.setState(setArgumentsList)

    loading()
  }, [page])
  
  return (
    <div className="school_challenge_argument_page">
      <div className="flex justify-content-between">
        <h1>Education - Quiz</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3>Argomenti</h3>

      <MyTable 
      first={first}
      values={argumentsList.getItemsPerWindow(page)}
      totalRecords={argumentsList.getTotalItems()}
      onRowSelection={({ value }) => navigate(routes.school_challenge_quiz + "/" + value.getId())}
      itemsPerPage={argumentsList.getPageSize()}
      columns={[
        {field: "image", header: "Immagine", body: (argument) => <Avatar image={argument.getImage()} size="large" shape="circle" />},
        {field: "name", header: "Nome"},
        {body: (argument) => <EditCell item={argument} removeFunction={removeArgument} editFunction={editFunction} /> }
      ]}
      />

      <Popup 
        title="Crea argomento"
        visible={popupVisible}
        setVisible={setPopupVisible}
        footer={<PopupFooter saveFunction={saveArgument} />}
        closeFunction={() => selectedArgument.reset()}>
          <label>Immagine</label>
          <div className="mt-2">
            <ImagePicker image_link={selectedArgument.getImage()} setImageLink={(value) => selectedArgument.setImage(value)}/>
          </div>
          <label>Nome</label>
          <InputText placeholder="Nome" value={selectedArgument.getName()} onChange={(e) => selectedArgument.setName(e.target.value)} className="w-full mt-2" /><br/><br/>
          <label>Descrizione</label>
          <InputText placeholder="Descrizione" value={selectedArgument.getDescription()} onChange={(e) => selectedArgument.setDescription(e.target.value)} className="w-full mt-2" /><br/><br/>
      </Popup>
    </div>
  )
}


export default SchoolChallenge