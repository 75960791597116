import React, { useEffect, useState, useContext } from 'react'
import { Button } from "primereact/button"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import "./style.css"
import Popup from '../../../../components/popup'
import ImagePicker from '../../../../components/image_picker'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import TopicController from '../../../../controllers/education/learn/topic_controller'
import TopicsListController from '../../../../controllers/education/learn/topics_list_controller'
import { useLocation, useNavigate, useParams } from 'react-router'
import routes from "../../../../router/routes"
import MyTable from '../../../../components/my_table/my_table'
import { Context } from "../../../../App"
import { confirmPopup } from 'primereact/confirmpopup'
import { toInteger } from 'lodash'
import { Avatar } from 'primereact/avatar'

function LearnArgument() {
  const [popupVisible, setPopupVisible] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState(new TopicController())
  const [topicsList, setTopicsList] = useState(new TopicsListController())
  const showMessage = useContext(Context)

  let { state } = useLocation()
  let params = useParams()
  let navigate = useNavigate()

  let macro_argument = params?.macro_argument
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / topicsList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    if(selectedTopic.getImage() == "") message.push("Immagine")
    if(selectedTopic.getTitle() == "") message.push("Nome")
    if(selectedTopic.getDescription() == "") message.push("Descrizione")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }
  
  async function loading(macro_argument, page) {
    let result = await topicsList.load(macro_argument, page)
    if(result.error) showMessage(true, result.message)
  }

  async function editTopic(topic) {
    selectedTopic.buildFromExport(topic.export())
    setPopupVisible(true)
  }

  async function removeTopic(topic) {
    let result = await topic.delete()
    if(result.error) showMessage(true, result.message)
    else {
      showMessage(false, "Operazione completata")
      topicsList.reset()
      loading(macro_argument, page)
    }
  }

  async function saveFunction() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedTopic.publish(macro_argument)
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        topicsList.reset()
        selectedTopic.reset()
        loading(macro_argument, page)
        setPopupVisible(false)
      }
    }
  }

  useEffect(() => {
    if(macro_argument == undefined) navigate(routes.learn)
    else {
      selectedTopic.setState(setSelectedTopic)
      topicsList.setState(setTopicsList)
      loading(macro_argument, page)
    }
  }, [page])
  
  
  return (
    <div id="learn_argument_page">
      <div className="flex justify-content-between">
        <h1>Education - Impara</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>


        <h3 className="ml-2">Argomenti</h3>

      <MyTable 
        first={first}
        values={topicsList.getTopicsPerWindow(page)}
        totalRecords={topicsList.getTotalItems()}
        onRowSelection={({ value }) => navigate(routes.learn_lessons + "/" + value.getId())}
        itemsPerPage={topicsList.getPageSize()}
        columns={[
          {field: "position", header: "Posizione"},
          {field: "image", header: "Immagine", body: (argument) => <Avatar image={argument.getImage()} shape="circle" size="large"/>},
          {field: "title", header: "Nome"},
          {field: "description", header: "Descrizione"},
          {body: (...props) => <EditCell {...props} editFunction={editTopic} removeTopic={removeTopic}/>}
        ]}
      />

      <Popup
      footer={<PopupFooter saveFunction={saveFunction}/>}
      title={"Crea Argomento"}
      visible={popupVisible}
      setVisible={setPopupVisible}
      closeFunction={() => selectedTopic.reset()}>
        <label>Immagine</label>
        <div className="mt-2">
          <ImagePicker className="mt-2" image_link={selectedTopic.getImage()} setImageLink={(image) => selectedTopic.setImage(image)} />
        </div>
        <div className="flex justify-content-between">
          <div>
            <label>Nome</label>
            <InputText placeholder="Nome" className="w-full mt-2" value={selectedTopic.getTitle()} onChange={(e) => selectedTopic.setTitle(e.target.value)}/>
          </div>
          <div>
            <label>Posizione</label>
            <InputText keyfilter="int" placeholder="Posizione" className="w-full mt-2" value={selectedTopic.getPosition()} onChange={(e) => selectedTopic.setPosition(toInteger(e.target.value))}/>
          </div>
        </div>
        <br/>
        <label>Descrizione</label>
        <InputTextarea placeholder="Descrizione" autoResize={true} className="w-full mt-2" value={selectedTopic.getDescription()} onChange={(e) => selectedTopic.setDescription(e.target.value)}/>
      </Popup>
    </div>
  )
}

function EditCell(props) {
  let topic = props[0]
  let editFunction = props.editFunction
  let removeTopic = props.removeTopic
  const [loading, setLoading] = useState(false)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await removeTopic(topic)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Button
      icon="pi pi-file-edit"
      text
      onClick={() => editFunction(topic)}/>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}
      loading={loading}/>
    </>
  )
}

function PopupFooter(props) {
  let saveFunction = props.saveFunction
  const [loading, setLoading] = useState(false)

  function save(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await saveFunction()
        setLoading(false)
      }
    })
  }
  return (
    <div>
      <Button label="Salva" loading={loading} onClick={save}/>
    </div>
  )
}

export default LearnArgument