import React, {useEffect, useState, useContext} from 'react'
import { Button } from 'primereact/button'
import Popup from "../../../../components/popup"
import PageController from '../../../../controllers/education/learn/page_controller'
import "./style.css"
import { useNavigate, useParams, useLocation } from 'react-router'
import routes from '../../../../router/routes'
import ImagePicker from '../../../../components/image_picker'
import { InputTextarea } from 'primereact/inputtextarea'
import { Context } from '../../../../App'
import PagesListController from '../../../../controllers/education/learn/pages_list_controller'
import MyTable from '../../../../components/my_table/my_table'
import { confirmPopup } from 'primereact/confirmpopup'
import { Avatar } from "primereact/avatar"
import { InputText } from 'primereact/inputtext'
import { toInteger } from 'lodash'

function LearnPages() {
  const [popupVisible, setPopupVisible] = useState(false)
  const [selectedPage, setSelectedPage] = useState(new PageController())
  const [pagesList, setPagesList] = useState(new PagesListController())

  let navigate = useNavigate()
  let showMessage = useContext(Context)
  let { state } = useLocation()
  let params = useParams()

  let lesson = params['lesson']
  let first = state?.first != undefined ? state['first'] : 0
  let page = Math.floor((first / pagesList.getPageSize()) + 1)

  function checkRequiredFields() {
    let message = []
    // if(selectedPage.getImage() == "") message.push("Immagine")
    if(selectedPage.getContent() == "") message.push("Contenuto")
    if(message.length != 0) showMessage(true, "Dati mancanti: " + message.toString())
    return message.length != 0
  }

  async function loading(lesson, page) {
    console.log("CI SONOOO")
    let result = await pagesList.load(lesson, page)
    if(result.error) showMessage(true, result.message)
  }

  async function savePage() {
    let error = checkRequiredFields()
    if(!error) {
      let result = await selectedPage.publish(lesson)
      if(result.error) showMessage(true, result.message)
      else {
        showMessage(false, "Operazione completata")
        selectedPage.reset()
        pagesList.reset()
        loading(lesson, page)
        setPopupVisible(false)
      }
    }
  }

  async function removePage(_page) {
    let result = await _page.delete()
    if(result.error) showMessage(true, result.message)
    else showMessage(false, "Operazione completata")
    pagesList.reset()
    loading(lesson, page)
  }

  async function editPage(page) {
    selectedPage.buildFromExport(page.export())
    setPopupVisible(true)
  }
  
  useEffect(() => {
    if(lesson == undefined) navigate(routes.learn)
    else {
      selectedPage.setState(setSelectedPage)
      pagesList.setState(setPagesList)
      loading(lesson, page)
    }
  }, [page])
  

  return (
    <div className="learn_pages_page">
      <div className="flex justify-content-between">
        <h1>Education - Impara</h1>
        <div className="flex align-items-center"><Button onClick={() => setPopupVisible(true)} label="Crea" /></div>
      </div>

      <h3 className="ml-2">Pagine</h3>

      <MyTable
      first={first}
      values={pagesList.getItemsPerWindow(page)}
      totalRecords={pagesList.getTotalItems()}
      onRowSelection={({ value }) => editPage(value)}
      itemsPerPage={pagesList.getPageSize()}
      arrowIconVisible={false}
      columns={[
        {header: "Posizione", field: "position"},
        {header: "Immagine", field: "image", body: (page) => <Avatar image={page.getImage()} size="xlarge" shape="circle"/>},
        {header: "Contenuto", field: "content"},
        {body: (page) => <EditCell page={page} editFunction={editPage} removePage={removePage}/>}
      ]}/>

      <Popup
        title="Crea pagina"
        visible={popupVisible}
        setVisible={setPopupVisible}
        footer={<Footer saveFunction={savePage}/>}
        closeFunction={() => selectedPage.reset()}
      >
        <label>Immagine</label>
        <div className="mt-2">
          <ImagePicker 
          image_link={selectedPage.getImage()}
          setImageLink={(value) => selectedPage.setImage(value)}/>
        </div>
        <label>Contenuto</label>
        <InputTextarea
        rows={5}
        autoResize
        className="w-full mt-2"
        placeholder="Contenuto"
        value={selectedPage.getContent()}
        onChange={(e) => selectedPage.setContent(e.target.value)}/><br/><br/>
        <label>Posizione</label>
        <InputText label="Posizione" value={selectedPage.getPosition()} onChange={(e) => selectedPage.setPosition(toInteger(e.target.value))} className="w-full mt-2" />
      </Popup>
    </div>
  )
}

function EditCell(props) {
  let page = props.page
  let editFunction = props.editFunction
  let removePage = props.removePage
  const [loading, setLoading] = useState(false)

  function remove(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await removePage(page)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Button
      icon="pi pi-file-edit"
      text
      onClick={() => editFunction(page)}/>
      <Button
      icon="pi pi-trash"
      text severity="danger"
      onClick={remove}
      loading={loading}/>
    </>
  )
}

function Footer(props) {
  let saveFunction = props.saveFunction
  const [loading, setLoading] = useState(false)

  function remove() {

  }

  function save(e) {
    confirmPopup({
      target: e.target,
      message: "Sei sicuro?",
      accept: async () => {
        setLoading(true)
        await saveFunction()
        setLoading(false)
      }
    })
  }

  return (
    <div>
      <Button label={"Salva"} onClick={save} loading={loading} />
    </div>
  )
}

export default LearnPages