import MyComponent from "../../base_components/my_component";
import StuffController from "./stuff_controller";

import { _delete_status } from "./stuff_controller";

class StuffListController extends MyComponent {
  constructor() {
    super()
    this.list = []
    this.university = true
  }

  // setter
  setList(value, auto_update = true) {
      if(!Array.isArray(value)) {
        console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
        return
      }

      this.list = value 
      if(auto_update) this.updateInfo()
  }

  setUniversity() { this.university = true }
  setWork() { this.university = false }

  // getter
  getList() { return this.list }
  getStuff() { return this.list.filter(stuff => stuff.status != _delete_status)}

  // API
  async publish(topic_slug) {
    let result = {error: false}
    for(let stuff of this.getList()) {
      result = await stuff.publish(topic_slug)
      if(result.error) {
        result.message = "Qualcosa è andato storto..."
        return result
      }
    }
    return result
  }

  load() {}

  // management
  addStuff(stuff = {}) {
    if(Array.isArray(stuff)) {
      console.warn(`[ ${this.constructor.name} ]`, "stuff is a list. Aborted")
      return
    } else if(stuff instanceof StuffController) {
      if(this.university) stuff.setUniversity()
      else stuff.setWork()
      this.list.push(stuff)
    } else if(typeof stuff == "object") {
      // creating stuff instance
      let newstuff = new StuffController()
      newstuff.setOverrideState((() => this.updateInfo()).bind(this))
      newstuff.buildFromExport(stuff)   // all the correctness aspects are inside the function
      if(this.university) newstuff.setUniversity()
      else newstuff.setWork()
      this.list.push(newstuff)
    }

    this.updateInfo()
  }

  deleteStuff(stuff) {
    if(typeof stuff == "string") {
      // stuff is the stuff id
      let stuffId = stuff
      stuff = this.list.find(item => item.getId() == stuffId)
      stuff.status = _delete_status
      // let index = this.list.indexOf(stuff)
      // this.list.splice(index, 1)
    } else if(stuff instanceof StuffController) {
      // stuff is an instance of StuffController
      stuff.status = _delete_status
      // let index = this.list.indexOf(stuff)
      // stuff.delete()
      // this.list.splice(index, 1)
    } else {
      console.warn(`[ ${this.constructor.name} ]`, "stuff is not in a valid type. Aborted")
      return
    }

    this.updateInfo()
  }

  buildFromExport(data) {
    data = super.__isValidObject(data)
    if(!data) {
      // data type error
      // error log printed in the super.buildFromExport()
      return
    }
    
    // type ckeck
    let keys = Object.keys(data)
    if(keys.includes("list") && !(Array.isArray(data['list']))) {
      console.warn(`[ ${this.constructor.name} ]`, "list is not a list. Aborted")
      return
    }

    // loading data
    if(keys.includes('list')) {
      for(let element of data['list']) this.addStuff(element)
    }

    this.updateInfo()
  }
}

export default StuffListController